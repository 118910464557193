const styles: any = {
  label: {
    color: '#707070',
    fontWeight: 'bold',
  },
  select: {
    width: '100%',
    // backgroundColor: '#E6E6E6',
    // border: '1px solid #707070',
  },
};

export default styles;