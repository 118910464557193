type Street = { id: number, name: string, neighborhoodId: number }

const streets: Array<Street> = [
  {
    "id": 1,
    "name": "RUA ESTRELA DO MAR (PRIMEIRA TRAVESSA ALTO DA BELA VISTA)",
    "neighborhoodId": 6,
  },
  {
    "id": 2,
    "name": "RUA OURICO DO MAR (SEGUNDA TRAVESSA ALTO DA BELA VISTA)",
    "neighborhoodId": 6,
  },
  {
    "id": 3,
    "name": "RUA FREI GODOFRED RAULF (ALTO DA CAPELINHA)",
    "neighborhoodId": 6,
  },
  {
    "id": 4,
    "name": "RUA ALTO DA BOA VISTA",
    "neighborhoodId": 6,
  },
  {
    "id": 5,
    "name": "RUA PARAISO",
    "neighborhoodId": 6,
  },
  {
    "id": 6,
    "name": "RUA SANTO ANTONIO",
    "neighborhoodId": 6,
  },
  {
    "id": 7,
    "name": "RUA DAS PALMEIRAS",
    "neighborhoodId": 6,
  },
  {
    "id": 8,
    "name": "RUA SAO JOAO",
    "neighborhoodId": 6,
  },
  {
    "id": 9,
    "name": "RUA VERAO",
    "neighborhoodId": 6,
  },
  {
    "id": 10,
    "name": "RUA PRIMAVERA",
    "neighborhoodId": 6,
  },
  {
    "id": 11,
    "name": "RUA OUTONO (TERCEIRA TRAVESSA ALTO DAS PALAMEDAEIRAS)",
    "neighborhoodId": 6,
  },
  {
    "id": 12,
    "name": "RUA NOSSA SENHORA DE MADRE DE DEUS",
    "neighborhoodId": 6,
  },
  {
    "id": 13,
    "name": "RUA BUZIOS (RUA DA GAMELEIRA)",
    "neighborhoodId": 6,
  },
  {
    "id": 14,
    "name": "RUA DAS ALGAS (ALTO DAS PITANGUEIRAS)",
    "neighborhoodId": 6,
  },
  {
    "id": 15,
    "name": "RUA AGUA VIVA (ALTO DA BELA VISTA)",
    "neighborhoodId": 6,
  },
  {
    "id": 16,
    "name": "RUA DO INDIO (ACESSO ALTO DO SANTO ANTONIO)",
    "neighborhoodId": 6,
  },
  {
    "id": 17,
    "name": "RUA NOSSA SENHORA DA AJUDA (RUA DO CEMITERIO)",
    "neighborhoodId": 6,
  },
  {
    "id": 18,
    "name": "RUA NOSSA SENHORA DO ROSARIO (ALTO DO CEMITERIO)",
    "neighborhoodId": 6,
  },
  {
    "id": 19,
    "name": "RUA PRESIDENTE CAMPOS SALES",
    "neighborhoodId": 10,
  },
  {
    "id": 20,
    "name": "RUA PRESIDENTE JANIOS QUADROS",
    "neighborhoodId": 10,
  },
  {
    "id": 21,
    "name": "RUA PRESIDENTE NILO PECANHA",
    "neighborhoodId": 10,
  },
  {
    "id": 22,
    "name": "RUA ERNANI ROCHA",
    "neighborhoodId": 10,
  },
  {
    "id": 23,
    "name": "RUA SANTOS DUMONT",
    "neighborhoodId": 10,
  },
  {
    "id": 24,
    "name": "RUA TANCREDO NEVES",
    "neighborhoodId": 10,
  },
  {
    "id": 25,
    "name": "RUA PRESIDENTE MARECHAL DEODORO",
    "neighborhoodId": 10,
  },
  {
    "id": 26,
    "name": "RUA PRESIDENTE MARECHAL FLORIANO PEIXOTO",
    "neighborhoodId": 10,
  },
  {
    "id": 27,
    "name": "RUA PRESIDENTE GETULIO VARGAS",
    "neighborhoodId": 10,
  },
  {
    "id": 28,
    "name": "RUA PRES EURICO GASPAR DUTRA",
    "neighborhoodId": 10,
  },
  {
    "id": 29,
    "name": "RUA PRIMEIRO DE MAIO",
    "neighborhoodId": 10,
  },
  {
    "id": 30,
    "name": "RUA FRANCISCO JOSE DE M RAMALHO (SEGUNDA TRAVESSA TREZE DE JUNHO)",
    "neighborhoodId": 10,
  },
  {
    "id": 31,
    "name": "PRACA DOIS DE JULHO",
    "neighborhoodId": 10,
  },
  {
    "id": 32,
    "name": "RUA PRESIDENTE ARTHUR BERNARDES",
    "neighborhoodId": 10,
  },
  {
    "id": 33,
    "name": "RUA PRESIDENTE AFONSO PENA",
    "neighborhoodId": 10,
  },
  {
    "id": 34,
    "name": "RUA PRESIDENTE MARECHAL H DA FONSECA",
    "neighborhoodId": 10,
  },
  {
    "id": 35,
    "name": "RUA AYRTON SENA (TRAVESSA SANTOS DUMONT)",
    "neighborhoodId": 10,
  },
  {
    "id": 36,
    "name": "RUA PRESIDENTE JUCELINO KUBISTSCHEK",
    "neighborhoodId": 10,
  },
  {
    "id": 37,
    "name": "RUA MAR CASPIO",
    "neighborhoodId": 13,
  },
  {
    "id": 38,
    "name": "RUA MAR EGEU (VERENDA TRES)",
    "neighborhoodId": 13,
  },
  {
    "id": 39,
    "name": "PRACA ZIZETE VELOSO",
    "neighborhoodId": 13,
  },
  {
    "id": 40,
    "name": "RUA SAO JOSE",
    "neighborhoodId": 12,
  },
  {
    "id": 41,
    "name": "RUA ESPIRITO SANTO (TERCEIRA TRAVESSA FRANCISCO LEITAO)",
    "neighborhoodId": 12,
  },
  {
    "id": 42,
    "name": "RUA SANTO ASTINHO",
    "neighborhoodId": 12,
  },
  {
    "id": 43,
    "name": "RUA SAO PAULO",
    "neighborhoodId": 12,
  },
  {
    "id": 44,
    "name": "RUA DO BARBEIRINHO",
    "neighborhoodId": 12,
  },
  {
    "id": 45,
    "name": "RUA SAO BRAS",
    "neighborhoodId": 12,
  },
  {
    "id": 46,
    "name": "RUA SAO PEDRO",
    "neighborhoodId": 5,
  },
  {
    "id": 47,
    "name": "RUA ANTONIO BALBINO",
    "neighborhoodId": 5,
  },
  {
    "id": 48,
    "name": "RUA SAO LAZARO",
    "neighborhoodId": 5,
  },
  {
    "id": 49,
    "name": "RUA RIO JEQUITINHONHA",
    "neighborhoodId": 5,
  },
  {
    "id": 50,
    "name": "RUA NOVA BRASILIA",
    "neighborhoodId": 2,
  },
  {
    "id": 51,
    "name": "RUA BRASIL",
    "neighborhoodId": 2,
  },
  {
    "id": 52,
    "name": "RUA ALAGOAS (RUA UM DO ASFALTO)",
    "neighborhoodId": 2,
  },
  {
    "id": 53,
    "name": "RUA MARANHAO (RUA DO CAIS)",
    "neighborhoodId": 2,
  },
  {
    "id": 54,
    "name": "RUA PERNAMBUCO",
    "neighborhoodId": 2,
  },
  {
    "id": 55,
    "name": "RUA AMAPA (PRIMEIRA TRAVESSA BAIXA DAS PITANGUEIRAS)",
    "neighborhoodId": 2,
  },
  {
    "id": 56,
    "name": "RUA RORAIMA",
    "neighborhoodId": 2,
  },
  {
    "id": 57,
    "name": "RUA SERGIPE",
    "neighborhoodId": 2,
  },
  {
    "id": 58,
    "name": "RUA RODONIA",
    "neighborhoodId": 2,
  },
  {
    "id": 59,
    "name": "RUA ACRE (ACESSO BRASIL)",
    "neighborhoodId": 2,
  },
  {
    "id": 60,
    "name": "RUA PARA",
    "neighborhoodId": 2,
  },
  {
    "id": 61,
    "name": "RUA MINAS GERAIS (TERCEIRA TRAVESSA BAIXA DAS PITANGUEIRAS)",
    "neighborhoodId": 2,
  },
  {
    "id": 62,
    "name": "PRAÇA GILDO HERMINIO DO SANTOS",
    "neighborhoodId": 2,
  },
  {
    "id": 63,
    "name": "RUA PITINGUARA",
    "neighborhoodId": 2,
  },
  {
    "id": 64,
    "name": "CAMINHO DOS RIBEIROS",
    "neighborhoodId": 11,
  },
  {
    "id": 65,
    "name": "CAMINHO DA LUZ (PRIMEIRA TRAVESSA CAMINHOINHO DA LUZ)",
    "neighborhoodId": 11,
  },
  {
    "id": 66,
    "name": "CAMINHO DO SALVADOR (RUA SALVADOR)",
    "neighborhoodId": 11,
  },
  {
    "id": 67,
    "name": "CAMINHO DO AMOR (RUA LUIZ MARIO)",
    "neighborhoodId": 11,
  },
  {
    "id": 68,
    "name": "CAMINHO DA ALEGRIA (TERCEIRA TRAVESSA LUIZ MARIO)",
    "neighborhoodId": 11,
  },
  {
    "id": 69,
    "name": "CAMINHO DO SOL",
    "neighborhoodId": 11,
  },
  {
    "id": 70,
    "name": "CAMINHO DA ESPERANCA (QUARTA TRAVESSA CAMINHOINHO DA LUZ)",
    "neighborhoodId": 11,
  },
  {
    "id": 71,
    "name": "CAMINHO DA FRATERNIDADE (PRIMEIRA TRAVESSA IARA CALDEIRA)",
    "neighborhoodId": 11,
  },
  {
    "id": 72,
    "name": "CAMINHO DA HARMONIA (SEGUNDA TRAVESSA IARA CALDEIRA)",
    "neighborhoodId": 11,
  },
  {
    "id": 73,
    "name": "CAMINHO DA PAZ (TERCEIRA TRAVESSA IARA CALDEIRA)",
    "neighborhoodId": 11,
  },
  {
    "id": 74,
    "name": "CAMINHO DA LUA (RUA IARA CALDEIRA)",
    "neighborhoodId": 11,
  },
  {
    "id": 75,
    "name": "AVENIDA RODOLFO DE QUEIROZ FILHO",
    "neighborhoodId": 1,
  },
  {
    "id": 76,
    "name": "RUA FRANCISCO LEITAO (RUA DO MAMAO)",
    "neighborhoodId": 1,
  },
  {
    "id": 77,
    "name": "AVENIDA MILTON BAHIA RIBEIRO (RUA DO ASFALTO BA 523)",
    "neighborhoodId": 1,
  },
  {
    "id": 78,
    "name": "RUA BENIGNO ANDRADE SOUZA (RUA DA MANGUEIRA)",
    "neighborhoodId": 1,
  },
  {
    "id": 79,
    "name": "PRACA PEDRO GOMES",
    "neighborhoodId": 1,
  },
  {
    "id": 80,
    "name": "RUA 7 DE SETEMBRO",
    "neighborhoodId": 1,
  },
  {
    "id": 81,
    "name": "RUA NELSON BARBOSA DE AZEVEDO (RUA DO CAMINHO)",
    "neighborhoodId": 1,
  },
  {
    "id": 82,
    "name": "RUA GENESIO SILVA",
    "neighborhoodId": 1,
  },
  {
    "id": 83,
    "name": "RUA ORLANDO PORTELA",
    "neighborhoodId": 1,
  },
  {
    "id": 84,
    "name": "RUA DERALDO ARAUJO BENTO (RUA CARLOS SEBASTIAO CRUZ)",
    "neighborhoodId": 10,
  },
  {
    "id": 85,
    "name": "RUA 21 DE ABRIL (RUA DO PEGUARI)",
    "neighborhoodId": 1,
  },
  {
    "id": 86,
    "name": "RUA MAR MEDITERRANEO (TRAVESSA CUPERTINO)",
    "neighborhoodId": 1,
  },
  {
    "id": 87,
    "name": "RUA DO DENDE",
    "neighborhoodId": 1,
  },
  {
    "id": 88,
    "name": "RUA NOVA JERUSALEM (PRIMEIRA TRAVESSA FRANCISCO LEITAO)",
    "neighborhoodId": 1,
  },
  {
    "id": 89,
    "name": "RUA NUMA POMPILHO GUIMARAES (PRIMEIRA TRAVESSA 21 DE ABRIL)",
    "neighborhoodId": 1,
  },
  {
    "id": 90,
    "name": "RUA AMANCIO FERREIRA (SEGUNDA TRAVESSA 21 DE ABRIL)",
    "neighborhoodId": 1,
  },
  {
    "id": 91,
    "name": "RUA JOAQUIM JOSE DA SILVA XAVIER (TERCEIRA TRAVESSA 21 DE ABRIL)",
    "neighborhoodId": 1,
  },
  {
    "id": 92,
    "name": "RUA GUADALAJARA",
    "neighborhoodId": 1,
  },
  {
    "id": 93,
    "name": "RUA DA INTENDENCIA (RUA DO MOTOR)",
    "neighborhoodId": 1,
  },
  {
    "id": 94,
    "name": "TRAVESSA ANTONIO AMOEDO DE BARROS (TRAVESSA SEBASTIAO CRUZ)",
    "neighborhoodId": 1,
  },
  {
    "id": 95,
    "name": "RUA DOM PEDRO I (PRIMEIRA BECO SETE DE SETEMBRO)",
    "neighborhoodId": 1,
  },
  {
    "id": 96,
    "name": "RUA DOM PEDRO II (SEGUNDA BECO 7 DE SETEMBRO)",
    "neighborhoodId": 1,
  },
  {
    "id": 97,
    "name": "RUA DOM JOAO VI (ACESSO GENESIO SILVA)",
    "neighborhoodId": 1,
  },
  {
    "id": 98,
    "name": "RUA PRESIDENTE EPITACIO PESSOA (BECO 21 DE ABRIL)",
    "neighborhoodId": 1,
  },
  {
    "id": 99,
    "name": "RUA PRINCESA ISABEL (TERCEIRA BECO 7 DE SETEMBRO)",
    "neighborhoodId": 1,
  },
  {
    "id": 100,
    "name": "RUA PRINCESA LEOPOLDINA (TRAVESSA 7 DE SETEMBRO)",
    "neighborhoodId": 1,
  },
  {
    "id": 101,
    "name": "RUA CLAUDIONOR BERNANDES",
    "neighborhoodId": 1,
  },
  {
    "id": 102,
    "name": "RUA FRANCISCO AMORIM (BECO FRANCISCO AMORIM)",
    "neighborhoodId": 1,
  },
  {
    "id": 103,
    "name": "RUA MENINO JESUS (SEGUNDA TRAVESSA FRANCISCO AMORIM)",
    "neighborhoodId": 1,
  },
  {
    "id": 104,
    "name": "RUA SAO DOMINS SAVIO",
    "neighborhoodId": 1,
  },
  {
    "id": 105,
    "name": "PRACA AFONSO CARDOSO RIBEIRO (RUA A)",
    "neighborhoodId": 1,
  },
  {
    "id": 106,
    "name": "RUA OCEANO PACIFICO (RUA B)",
    "neighborhoodId": 1,
  },
  {
    "id": 107,
    "name": "RUA OCEANO ATLANTICO (RUA C)",
    "neighborhoodId": 1,
  },
  {
    "id": 108,
    "name": "RUA 13 DE JUNHO",
    "neighborhoodId": 1,
  },
  {
    "id": 109,
    "name": "RUA COSTA DO PALAME",
    "neighborhoodId": 1,
  },
  {
    "id": 110,
    "name": "RUA DEIJAIR MARIA PINHEIRO",
    "neighborhoodId": 1,
  },
  {
    "id": 111,
    "name": "RUA VALDEMIRA DE QUEIROZ REIS (VERENDA UM)",
    "neighborhoodId": 1,
  },
  {
    "id": 112,
    "name": "RUA LUIZ EDUARDO MAGALHAES (ACESSO FRANCISCO LEITAO)",
    "neighborhoodId": 1,
  },
  {
    "id": 113,
    "name": "PRAÇA TREZE DE JUNHO",
    "neighborhoodId": 1,
  },
  {
    "id": 114,
    "name": "RUA BURITI (SEGUNDA TRAVESSA DO DENDE)",
    "neighborhoodId": 1,
  },
  {
    "id": 115,
    "name": "TRAVESSA FRANCISCO LEITAO (BECO FRANCISCO LEITAO)",
    "neighborhoodId": 1,
  },
  {
    "id": 116,
    "name": "RUA XV NOVEMBRO (LOTEAMENTO PROXIMO A PRACA 13 DE JUNHO)",
    "neighborhoodId": 1,
  },
  {
    "id": 117,
    "name": "RUA DA REPUBLICA (TRAVESSA DA INTENDENCIA)",
    "neighborhoodId": 1,
  },
  {
    "id": 118,
    "name": "RUA AGENOR MARQUES DA SILVA (PRIMEIRA TRAVESSA DO DENDE)",
    "neighborhoodId": 1,
  },
  {
    "id": 119,
    "name": "RUA DO IMPERIO (ACESSO DA INTENDENCIA)",
    "neighborhoodId": 1,
  },
  {
    "id": 120,
    "name": "RUA SANTA BARBARA",
    "neighborhoodId": 1,
  },
  {
    "id": 121,
    "name": "PRACA RUFINO HU PITANGUEIRA",
    "neighborhoodId": 1,
  },
  {
    "id": 122,
    "name": "RUA NOSSA SENHORA MAE DOS HOMENS (RUA DA MATERNIDADE)",
    "neighborhoodId": 1,
  },
  {
    "id": 123,
    "name": "RUA PINTASSILGA",
    "neighborhoodId": 1,
  },
  {
    "id": 124,
    "name": "RUA COTOVIA",
    "neighborhoodId": 1,
  },
  {
    "id": 125,
    "name": "RUA BEM TI VI",
    "neighborhoodId": 1,
  },
  {
    "id": 126,
    "name": "RUA OCEANO INDICO (RUA A)",
    "neighborhoodId": 1,
  },
  {
    "id": 127,
    "name": "PRACA JUVENAL AMORIM",
    "neighborhoodId": 1,
  },
  {
    "id": 128,
    "name": "RUA CURURUPEBA (RUA DA GRANJA UM)",
    "neighborhoodId": 4,
  },
  {
    "id": 129,
    "name": "RUA RIO ITAPECURU",
    "neighborhoodId": 7,
  },
  {
    "id": 130,
    "name": "RUA LUIZ VIANA BERNADES",
    "neighborhoodId": 3,
  },
  {
    "id": 131,
    "name": "PRACA DO MIRIM",
    "neighborhoodId": 3,
  },
  {
    "id": 132,
    "name": "RUA PORTO DO MIRIM",
    "neighborhoodId": 3,
  },
  {
    "id": 133,
    "name": "RUA SANTA HELENA (RUA A)",
    "neighborhoodId": 15,
  },
  {
    "id": 134,
    "name": "RUA SANTA CECILIA (RUA C)",
    "neighborhoodId": 15,
  },
  {
    "id": 135,
    "name": "RUA JERONIMO MARTINS ALVES (RUA B)",
    "neighborhoodId": 15,
  },
  {
    "id": 136,
    "name": "RUA SANTA LUCIA (RUA D)",
    "neighborhoodId": 15,
  },
  {
    "id": 137,
    "name": "RUA BENICIO NASCIMENTO (RUA E)",
    "neighborhoodId": 15,
  },
  {
    "id": 138,
    "name": "RUA SANTA TEREZINHA (RUA F)",
    "neighborhoodId": 15,
  },
  {
    "id": 139,
    "name": "RUA SANTA CLARA (RUA G)",
    "neighborhoodId": 15,
  },
  {
    "id": 140,
    "name": "RUA SANTA MARTA (RUA H)",
    "neighborhoodId": 15,
  },
  {
    "id": 141,
    "name": "RUA SANTA RITA",
    "neighborhoodId": 15,
  },
  {
    "id": 142,
    "name": "RUA DOLORES DURAND",
    "neighborhoodId": 8,
  },
  {
    "id": 143,
    "name": "RUA CHIQUINHA NZAGA (RUA JOANA ANGELICA)",
    "neighborhoodId": 8,
  },
  {
    "id": 144,
    "name": "RUA CARMEN MIRANDA",
    "neighborhoodId": 8,
  },
  {
    "id": 145,
    "name": "RUA MARIA QUITERIA",
    "neighborhoodId": 8,
  },
  {
    "id": 146,
    "name": "RUA SOROR JOANA ANGELICA",
    "neighborhoodId": 8,
  },
  {
    "id": 147,
    "name": "RUA BRASILIA",
    "neighborhoodId": 4,
  },
  {
    "id": 148,
    "name": "RUA RIO GUARA (ACESSO DEA GRANJA)",
    "neighborhoodId": 4,
  },
  {
    "id": 149,
    "name": "RUA RIO DE CONTAS (RUA DO COQUEIRO)",
    "neighborhoodId": 4,
  },
  {
    "id": 150,
    "name": "RUA RIO JOANES (TRAVESSA DO COQUEIRO)",
    "neighborhoodId": 4,
  },
  {
    "id": 151,
    "name": "RUA RIO PARAGUASSU (RUA SUAPE OU RUA DO MEIO)",
    "neighborhoodId": 4,
  },
  {
    "id": 152,
    "name": "RUA RIO SAO FRANCISCO (RUA SUAPE DE CIMA)",
    "neighborhoodId": 4,
  },
  {
    "id": 153,
    "name": "RUA RIO JEQUIRICA (RUA SUAPE DE BAIXO)",
    "neighborhoodId": 4,
  },
  {
    "id": 154,
    "name": "RUA RIO PARAMIRIM (ACESSO SUAPE DE BAIXO)",
    "neighborhoodId": 4,
  },
  {
    "id": 155,
    "name": "RUA RIO GRANDE",
    "neighborhoodId": 4,
  },
  {
    "id": 156,
    "name": "RUA ENEDINA MADALENA DO CARMO (RUA AGUA COMPRIDA)",
    "neighborhoodId": 4,
  },
  {
    "id": 157,
    "name": "RUA RIO POJUCA",
    "neighborhoodId": 4,
  },
  {
    "id": 158,
    "name": "RUA APICUM",
    "neighborhoodId": 14,
  },
  {
    "id": 159,
    "name": "AVENIDA BEIRA MAR",
    "neighborhoodId": 14,
  },
  {
    "id": 160,
    "name": "TRAVESSA ACESSO TONHE",
    "neighborhoodId": 14,
  },
  {
    "id": 161,
    "name": "RUA FRANCISCO SILVEIRA",
    "neighborhoodId": 2,
  },
  {
    "id": 162,
    "name": "RUA DA GRANJA",
    "neighborhoodId": 4,
  },
  {
    "id": 163,
    "name": "RUA SAO DOMINS SAVIO (TRAVESSA A)",
    "neighborhoodId": 15,
  },
  {
    "id": 164,
    "name": "RUA CAMINHO SALES (PRIMEIRA TRAVESSA DO ASFATO)",
    "neighborhoodId": 10,
  },
  {
    "id": 165,
    "name": "RUA JANIO QUADROS (SEGUNDA TRAVESSA DO ASFALTO)",
    "neighborhoodId": 10,
  },
  {
    "id": 166,
    "name": "RUA NILO PECANHA (TRAVESSA ERNANI ROCHA)",
    "neighborhoodId": 10,
  },
  {
    "id": 167,
    "name": "RUA PRESIDENTE EURICO GASPAR DUTRA (TERCEIRA TRAVESSA TANCREDO NEVES)",
    "neighborhoodId": 10,
  },
  {
    "id": 168,
    "name": "RUA SANTOS DUMONT (TRAVESSA DE VITRCA)",
    "neighborhoodId": 10,
  },
  {
    "id": 169,
    "name": "RUA BOSQUE DAS ALAMEDAS (QUINTA TRAVESSA ANTONIO BALBINO)",
    "neighborhoodId": 5,
  },
  {
    "id": 170,
    "name": "RUA HORTO FLORESTAL (SEGUNDA TRAVESSA ANTONIO BALBINO)",
    "neighborhoodId": 5,
  },
  {
    "id": 171,
    "name": "RUA HORTO IMPERIAL (PRIMEIRA TRAVESSA ANTONIO BALBINO)",
    "neighborhoodId": 5,
  },
  {
    "id": 172,
    "name": "RUA RECANTO DOS BOSQUE (QUARTA TRAVESSA ANTONIO BALBINO)",
    "neighborhoodId": 5,
  },
  {
    "id": 173,
    "name": "RUA TERCEIRA RECANTO DO CACAO (TERCEIRA TRAVESSA ANTONIO BALBINO)",
    "neighborhoodId": 5,
  },
  {
    "id": 174,
    "name": "RUA TSYLA BALBINO (TRAVESSA ANTONIO BALBINO)",
    "neighborhoodId": 5,
  },
  {
    "id": 175,
    "name": "RUA VIVENDAS DAS ROCHAS (SEXTA TRAVESSA ANTONIO BALBINO)",
    "neighborhoodId": 5,
  },
  {
    "id": 176,
    "name": "TRAVESSA PRIMEIRA ANTONIO BALBINO",
    "neighborhoodId": 5,
  },
  {
    "id": 177,
    "name": "ALAMEDA CESAR BORGES",
    "neighborhoodId": 1,
  },
  {
    "id": 178,
    "name": "RUA 2 DE JULHO (QUARTA TRAVESSA TANCREDO NEVES E PRACA DO ESTADIO)",
    "neighborhoodId": 1,
  },
  {
    "id": 179,
    "name": "RUA DAS PALAMEDAEIRAS (ALTO DAS PALAMEDAEIRAS)",
    "neighborhoodId": 1,
  },
  {
    "id": 181,
    "name": "RUA GETULIO VARGAS (BECO PRIMEIRA DO ASFALTO)",
    "neighborhoodId": 1,
  },
  {
    "id": 182,
    "name": "RUA MAR CASPIO (VERENDA DOIS)",
    "neighborhoodId": 1,
  },
  {
    "id": 183,
    "name": "RUA MARECHAL DEODORO DA FONSECA (PRIMEIRA TRAVESSA TANCREDO NEVES)",
    "neighborhoodId": 1,
  },
  {
    "id": 184,
    "name": "RUA MARECHAL FLORIANO PEIXOTO (SEGUNDA TRAVESSA TANCREDO NEVES)",
    "neighborhoodId": 1,
  },
  {
    "id": 185,
    "name": "RUA NOSSA SENHORA MADRE DE DEUS (ALTO DA MATRIZ)",
    "neighborhoodId": 1,
  },
  {
    "id": 186,
    "name": "RUA PARA (ACESSO ALTO BELA VISTA)",
    "neighborhoodId": 1,
  },
  {
    "id": 187,
    "name": "RUA PARAISO (ALTO DO PARAISO)",
    "neighborhoodId": 1,
  },
  {
    "id": 188,
    "name": "RUA PERNAMBUCO (BAIXA DA PITANGUEIRAS)",
    "neighborhoodId": 1,
  },
  {
    "id": 189,
    "name": "RUA PITIGUARA (MAREZINHA)",
    "neighborhoodId": 1,
  },
  {
    "id": 190,
    "name": "RUA PORTO DO MIRIM",
    "neighborhoodId": 1,
  },
  {
    "id": 191,
    "name": "RUA PRESIDENTE AFONSO PENA (CAMINHOINHO DO ASFALTO)",
    "neighborhoodId": 1,
  },
  {
    "id": 192,
    "name": "RUA PRESIDENTE ARTHUR BERNARDES (ACESSO QUINTO DO ASFALTO)",
    "neighborhoodId": 1,
  },
  {
    "id": 193,
    "name": "RUA PRESIDENTE EURICO GASPAR DUTRA (TERCEIRA TRAVESSA TANCREDO NEVES)",
    "neighborhoodId": 1,
  },
  {
    "id": 194,
    "name": "RUA PRESIDENTE JUSCELINO KUBITSCHEK",
    "neighborhoodId": 1,
  },
  {
    "id": 195,
    "name": "RUA PRESIDENTE MARECHAL HERMES FONSECA (TRAVESSA CLAUDIONOR BERNARDES)",
    "neighborhoodId": 1,
  },
  {
    "id": 196,
    "name": "RUA PRIMAVERA (SEGUNDA TRAVESSA ALTO DAS PALAMEDAEIRAS)",
    "neighborhoodId": 1,
  },
  {
    "id": 197,
    "name": "RUA PRIMEIRA DE MAIO (PRIMEIRA TRAVESSA TREZE DE JUNHO)",
    "neighborhoodId": 1,
  },
  {
    "id": 198,
    "name": "RUA RONDONIA (ACESSO TRES DO ASFALTO)",
    "neighborhoodId": 1,
  },
  {
    "id": 199,
    "name": "RUA RORAIMA (SEGUNDA TRAVESSA BAIXA DAS PITANGUEIRAS)",
    "neighborhoodId": 1,
  },
  {
    "id": 200,
    "name": "RUA SANTA RITA",
    "neighborhoodId": 1,
  },
  {
    "id": 201,
    "name": "RUA SANTO ASTINHO (PRIMEIRA TRAVESSA DO BARBEIRINHO)",
    "neighborhoodId": 1,
  },
  {
    "id": 202,
    "name": "RUA SANTO ANTONIO (ALTO DO SANTO ANTONIO)",
    "neighborhoodId": 1,
  },
  {
    "id": 203,
    "name": "RUA SANTOS DUMONT (TRAVESSA DE VITRCA)",
    "neighborhoodId": 1,
  },
  {
    "id": 204,
    "name": "RUA SÃO BRAS (TERCEIRA TRAVESSA DO BARBEIRINHO)",
    "neighborhoodId": 1,
  },
  {
    "id": 205,
    "name": "RUA SAO JOAO (TRAVESSA ALTO DO SANTO ANTONIO)",
    "neighborhoodId": 1,
  },
  {
    "id": 206,
    "name": "RUA SÃO JOSÉ (SEGUNDA TRAVESSA DO BARBEIRINHO)",
    "neighborhoodId": 1,
  },
  {
    "id": 207,
    "name": "RUA SAO PAULO (BECO DO BARBEIRINHO)",
    "neighborhoodId": 1,
  },
  {
    "id": 208,
    "name": "RUA SAO PEDRO",
    "neighborhoodId": 1,
  },
  {
    "id": 209,
    "name": "RUA SERGIPE (ACESSO DOIS DO ASFALTO)",
    "neighborhoodId": 1,
  },
  {
    "id": 210,
    "name": "RUA TANCREDO NEVES",
    "neighborhoodId": 1,
  },
  {
    "id": 211,
    "name": "RUA VERAO (PRIMEIRA TRAVESSA ALTO DAS PALAMEDAEIRAS)",
    "neighborhoodId": 1,
  },
  {
    "id": 212,
    "name": "TRAVESSA DO ESTADIO (PRIMEIRA TRAVESSA DO ESTADIO)",
    "neighborhoodId": 1,
  },
  {
    "id": 214,
    "name": "RUA ACAJUTIBA (NOVA CURURUPEBA)",
    "neighborhoodId": 7,
  },
  {
    "id": 215,
    "name": "RUA ARAXAS (NOVA CURURUPEBA)",
    "neighborhoodId": 7,
  },
  {
    "id": 216,
    "name": "RUA ATIBAIA (NOVA CURURUPEBA)",
    "neighborhoodId": 7,
  },
  {
    "id": 217,
    "name": "RUA ATIBAIA 1 (RUAS EX OFICIO)",
    "neighborhoodId": 7,
  },
  {
    "id": 218,
    "name": "RUA ATIBAIA 2 (RUAS EX OFICIO)",
    "neighborhoodId": 7,
  },
  {
    "id": 219,
    "name": "RUA ATIBAIA 3 (RUAS EX OFICIO)",
    "neighborhoodId": 7,
  },
  {
    "id": 220,
    "name": "RUA ATIBAIA 4 (RUAS EX OFICIO)",
    "neighborhoodId": 7,
  },
  {
    "id": 221,
    "name": "RUA DAS FLORES (NOVA CURURUPEBA)",
    "neighborhoodId": 7,
  },
  {
    "id": 222,
    "name": "RUA DAS FONTES (NOVA CURURUPEBA)",
    "neighborhoodId": 7,
  },
  {
    "id": 223,
    "name": "RUA DAS POMBAS (NOVA CURURUPEBA)",
    "neighborhoodId": 7,
  },
  {
    "id": 224,
    "name": "RUA DOS JESUITAS (NOVA CURURUPEBA)",
    "neighborhoodId": 7,
  },
  {
    "id": 225,
    "name": "RUA GUAPORE (NOVA CURURUPEBA)",
    "neighborhoodId": 7,
  },
  {
    "id": 226,
    "name": "RUA GUARA (NOVA CURURUPEBA)",
    "neighborhoodId": 7,
  },
  {
    "id": 227,
    "name": "RUA IBIA (NOVA CURURUPEBA)",
    "neighborhoodId": 7,
  },
  {
    "id": 228,
    "name": "RUA IGUATEMI (NOVA CURURUPEBA)",
    "neighborhoodId": 7,
  },
  {
    "id": 229,
    "name": "RUA MEM DE SA (NOVA CURURUPEBA)",
    "neighborhoodId": 7,
  },
  {
    "id": 230,
    "name": "RUA TUPINAMBA (NOVA CURURUPEBA)",
    "neighborhoodId": 7,
  },
  {
    "id": 231,
    "name": "TRAVESSA APICUM ACESSO DOE",
    "neighborhoodId": 14,
  },
  {
    "id": 232,
    "name": "RUA PORTO DO MIRIM",
    "neighborhoodId": 3,
  },
  {
    "id": 233,
    "name": "RUA SANTA BARBARA (TRAVESSA D)",
    "neighborhoodId": 15,
  },
  {
    "id": 234,
    "name": "RUA DA QUITERIA 1",
    "neighborhoodId": 8,
  },
  {
    "id": 235,
    "name": "RUA DA QUITERIA 2",
    "neighborhoodId": 8,
  },
  {
    "id": 236,
    "name": "RUA DA QUITERIA 3",
    "neighborhoodId": 8,
  },
  {
    "id": 237,
    "name": "RUA DA QUITERIA 4",
    "neighborhoodId": 8,
  },
  {
    "id": 238,
    "name": "RUA DA QUITERIA 5",
    "neighborhoodId": 8,
  },
  {
    "id": 239,
    "name": "RUA DA QUITERIA 6",
    "neighborhoodId": 8,
  },
  {
    "id": 240,
    "name": "RUA RIO ITAPICURU (RUA DA GRANJA DOIS)",
    "neighborhoodId": 4,
  },
  {
    "id": 241,
    "name": "RUA CURURUPEBA 1 (RUAS EX OFICIO)",
    "neighborhoodId": 7,
  },
  {
    "id": 242,
    "name": "RUA RIO JEQUITINHONHA (ACESSO A ESCOLA ACM)",
    "neighborhoodId": 4,
  }
];

export default streets;