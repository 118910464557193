import React, { useEffect } from 'react';

import { Grid, withStyles } from '@material-ui/core';

import styles from './styles';

type Props = { classes: any, history: any };

function ConfirmPage(props: Props) {
  const { classes, history } = props;

  useEffect(() => {
    const benefited = history.location.state?.benefited;
    if (!benefited) {
      history.push('/');
    }
  }, [history])

  return (
    <Grid container className={classes.mainGrid}>
      <Grid item xs={11}>
        <img alt="Logo Madre" src="/assets/logo_travessia.png" className={classes.logoSfc} />
      </Grid>
      <Grid item xs={11} className={classes.infoContainer}>
        <Grid item xs={12} sm={6} className={classes.divContent}>
          <span className={classes.title22}>Cadastro concluído com sucesso!</span>
          <Grid item xs={12} className={classes.cardLogin}>
            <img alt="Telefone" src="/assets/phone.png" className={classes.imgPhone} />
            <span className={classes.title22Mobile}>Cadastro concluído com sucesso!</span>
            <span className={classes.title16BlackLeft}>
              Parabéns, você concluiu o seu cadastro!
            </span>
            <span className={classes.title16BlackLeft}>
              Agora é só aguardar! Seus dados serão submetidos a análise para liberação do benefício.
            </span>
          </Grid>
        </Grid>

      </Grid>
      <Grid item xs={12} className={classes.divCenter}>
        <span className={classes.spanBottom}>Por <b>Horizon - Inovação e Tecnologia</b></span>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(ConfirmPage);