import React from 'react';
import {
  withStyles,
  InputLabel,
  Select,
  FormControl,
  MenuItem,
} from '@material-ui/core';
import styles from './styles';

type Props = { classes: any, label: string, value: string, name: string, onChange: (e: any) => any, array?: any[] };

function SelectField(props: Props) {
  const { classes, label, value, onChange, name, array } = props;
  return (
    <FormControl variant="outlined" className={classes.select}>
      <InputLabel>{label}</InputLabel>
      <Select
        fullWidth
        label={label}
        value={value}
        onChange={(event: any) => onChange(event.target.value)}
        name={name}
      >
        {array?.map((object: any) => <MenuItem key={object.name} value={object.name}>{object.name}</MenuItem>)}
      </Select>
    </FormControl>
  )
}

export default withStyles(styles)(SelectField);