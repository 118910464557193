import React from 'react';
import {  Grid, withStyles } from '@material-ui/core';
import styles from './styles';

type Props = { classes: any };

function TerminatePage(props: Props) {
  const { classes  } = props;
  
  return (
    <Grid container className={classes.mainGrid}>
      <Grid item xs={12} className={classes.divLogo}>
        <img alt="Logo Madre" src="/assets/logo_travessia.png" className={classes.mainLogo} />
      </Grid>
        <Grid container spacing={2} md={5} sm={6} xs={11} className={classes.cardMessage}>
          <Grid item xs={12} className={classes.titleItem}>
              <span className={classes.title18}>Inscrições encerradas</span>
          </Grid>
          <Grid item xs={12} className={classes.titleItem}>
            <span className={classes.title16}>Aguarde a avaliação da sua solicitação</span>
          </Grid>
        </Grid>
      <Grid item xs={12} className={classes.divBottom}>
        <img alt="Logo Madre" src="/assets/logo_madre.png" className={classes.madreLogo} />
        <span className={classes.spanBottom}>Por <b>Horizon - Inovação e Tecnologia</b></span>
      </Grid>
    </Grid>
  )
}
export default withStyles(styles)(TerminatePage);