const styles: any = {
    mainGrid: {
      display: 'flex',
      minHeight: '100vh',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#F9F9F9',
      backgroundImage: 'url(/assets/bg_home.png)',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    },
    divLogo: {
      display: 'flex',
      justifyContent: 'space-evenly',
      flexDirection: 'column',
      alignItems: 'center',
    },
    mainLogo: {
        maxWidth: '250px',
        padding: '50px',
    },
    cardMessage: {
        backgroundColor: '#FFFFFF',
        borderRadius: 20,
        padding: '40px 50px',
        justifyContent: 'center',
        boxShadow: '0px 0 20px #00000033',
    },
    titleItem: {
        display: 'flex',
        justifyContent: 'center',
        padding: '0px !important',
    },
    title18: {
        fontWeight: '800',
        fontSize: 18,
        color: '#3E3E3E',
        textAlign: 'center',
        margin: '0 0 5px',
    },
    title16: {
        fontWeight: '400',
        fontSize: 15,
        color: '#3E3E3E',
        margin: '5px 0 25px',
        textAlign: 'center',
    },
    madreLogo: {
        maxWidth: '120px',
        width: '100%',
        padding: '0 10px',
        marginBottom: 15,
    },
    divBottom: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column', 
      alignItems: 'center'
    },
    spanBottom: {
        color: '#FFFFFF',
        fontSize: 14
    }
};

export default styles;