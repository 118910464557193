import React from 'react';
import Router from './Router';
import './App.css';
import AuthProvider from './providers/Auth';

function App() {
  return (
    <AuthProvider>
      <Router />
    </AuthProvider>
  );
}

export default App;
