import React from 'react';
import { Grid, TextField, withStyles, FormControlLabel, Checkbox } from '@material-ui/core';
import styles from './styles';

type Props = { classes: any, label: string, name: string, type?: string, placeholder?: string, onUnkownChecked: (e: any) => void, disabled?: boolean, value: any, onChange: (event: any) => void, checked?: boolean };

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#707070',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#0000003b',
    },
    '& .MuiOutlinedInput-root': {
      // backgroundColor: '#E6E6E6',
      borderRadius: '4px 0 0 4px',
      '& fieldset': {
        borderColor: '#0000003b',
        // borderRight: 0,
      },
      '&:hover fieldset': {
        borderColor: '#0000003b',
      },
      '&.Mui-focused fieldset': {
        // borderRight: 0,
        // border: '1px solid #0000003b',
        borderColor: '#0000003b',
      },
    },
    '& .MuiInputBase-input': {
      '&::placeholder': {
        fontSize: 14,
      }
    }
  },
})(TextField);

function TextFieldWithUnkonwOption(props: Props) {
  const { classes, label, name, type, onUnkownChecked, disabled, placeholder, value, onChange, checked } = props;

  return (
    <Grid item xs={12}>
      {/* <InputLabel shrink className={classes.label}>{label}</InputLabel> */}
      <Grid item xs={12} style={{ display: 'flex', flexWrap: 'wrap' }}>
        <Grid item xs={12} sm={7}>
          <CssTextField
            // required
            fullWidth
            variant="outlined"
            name={name}
            type={type}
            disabled={disabled}
            placeholder={placeholder}
            label={label}
            value={value}
            onChange={(event: any) => onChange(event.target.value)}
            InputLabelProps={{
              style: { color: '#707070', fontSize: '14px' },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={5} className={classes.checkboxDiv}>
          <FormControlLabel
            style={{ marginRight: 0 }}
            control={
              <Checkbox
                checked={checked}
                onChange={(e: any) => onUnkownChecked(e.target.checked)}
                name="unknown"
                color="primary"
              />
            }
            label="Desconhecido"
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(TextFieldWithUnkonwOption);