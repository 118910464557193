const styles: any = {
  mainGrid: {
    display: 'flex',
    minHeight: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F9F9F9',
    backgroundImage: 'url(/assets/bg_home.png)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  divMain: {
    display: 'flex',
    minHeight: '100vh',
    justifyContent: 'space-evenly',
    flexDirection: 'column',
    alignItems: 'center',
  },
  divCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  divInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  divButton: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 10,
    marginBottom: 0,
  },
  cardLogin: {
    backgroundColor: '#FFFFFF',
    borderRadius: 20,
    padding: '40px 50px',
    justifyContent: 'center',
    boxShadow: '0px 0 20px #00000033'
  },
  mainLogo: {
    maxWidth: '240px',
    width: '100%',
    padding: '0 10px',
    // marginBottom: 15,
  },
  madreLogo: {
    maxWidth: '120px',
    width: '100%',
    padding: '0 10px',
    marginBottom: 15,
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '0px !important',
  },
  title22: {
    margin: '10px 30px 30px',
    fontWeight: '600',
    fontSize: 22,
    color: '#707070',
    textAlign: 'center'
  },
  title18: {
    fontWeight: '700',
    fontSize: 18,
    color: '#3E3E3E',
    textAlign: 'center',
    margin: '0 0 5px',
  },
  title16: {
    fontWeight: '400',
    fontSize: 15,
    color: '#3E3E3E',
    margin: '5px 0 25px',
    textAlign: 'center',
  },
  spanBottom: {
    color: '#FFFFFF',
    fontSize: 14
  },
  restrictedAreaButton: {
    alignSelf: 'flex-end',
    marginTop: '-30px',
    marginRight: '20px',
  },
};

export default styles;