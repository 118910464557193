import React from 'react';
import ReactInputMask from 'react-input-mask';
import { Grid, TextField, withStyles, FormControlLabel, Checkbox } from '@material-ui/core';
import styles from './styles';

type Props = { classes: any, label: string, name: string, mask: string, type?: string, placeholder?: string, checkLabel?: string, onUnkownChecked: (e: any) => void, disabled?: boolean, value: any, onChange: (event: any) => void, checked?: boolean };

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#707070',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#0000003b',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '4px 0 0 4px',
      '& fieldset': {
        borderColor: '#0000003b',
      },
      '&:hover fieldset': {
        borderColor: '#0000003b',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#0000003b',
      },
    },
    '& .MuiInputBase-input': {
      '&::placeholder': {
        fontSize: 14,
      }
    }
  },
})(TextField);

function TextFieldWithMaskAndOption(props: Props) {
  const { classes, label, name, type, mask, checkLabel, onUnkownChecked, disabled, placeholder, value, onChange, checked } = props;

  return (
    <Grid item xs={12}>
      <Grid item xs={12} style={{ display: 'flex', flexWrap: 'wrap' }}>
        <Grid item xs={12} sm={7}>
          <ReactInputMask
            mask={mask}
            type={type}
            value={value}
            onChange={(event: any) => onChange(event.target.value as string)}
            name={name}
          >
            {() => (
              <CssTextField
                fullWidth
                variant="outlined"
                label={label}
                disabled={disabled}
                placeholder={placeholder}
                InputLabelProps={{
                  style: { color: '#707070', fontSize: '14px' },
                }}
              />
            )}
          </ReactInputMask>
        </Grid>
        <Grid item xs={12} sm={5} className={classes.checkboxDiv}>
          <FormControlLabel
            style={{ marginRight: 0 }}
            control={
              <Checkbox
                checked={checked}
                onChange={(e: any) => onUnkownChecked(e.target.checked)}
                name="unknown"
                color="primary"
              />
            }
            label={checkLabel}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(TextFieldWithMaskAndOption);