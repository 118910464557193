import api from './api';
import Benefited from '../model/entities/Benefited';

class UserService { 
  async getAllByCpfOrNic(cpfOrNic: string) {
    const res = await api.get(`get_users/${cpfOrNic}`);
    const { data } = res.data;
    console.log(data);
    return data;
  }

  async updateUser(benefited: Benefited, userId: string) {
    const res = await api.post('update_user', { benefited, userId });
    const { data } = res.data;
    console.log(data);
    return data;
  }

  async updateAddress(adress: any, userId: string) {
    const res = await api.post('update_address', { adress, userId });
    const { data } = res.data;
    console.log(data);
    return data;
  }
}

export default new UserService();