import firebaseConfig from '../config/firebase';

import { Filter } from '../model/entities/Filter';
import Benefited from '../model/entities/Benefited';

class BenefitedService {
  private DEFAULT_REF = 'benefited';

  public async getAllWithFilter(filter: Filter[], getOptions?: any) {
    return firebaseConfig.getAllDataWithFilter(
      this.DEFAULT_REF,
      filter,
      getOptions
    );
  }

  public async getAll(getOptions?: any) {
    return firebaseConfig.getAllData(
      this.DEFAULT_REF,
      getOptions
    );
  }

  public async get(uid: string, getOptions?: any) {
    const documentPath = uid
    return firebaseConfig.getData(
      this.DEFAULT_REF,
      documentPath,
      getOptions
    );
  }

  public async create(benefited: any) {
    await firebaseConfig.addData(
      this.DEFAULT_REF,
      benefited
    );
  }

  public async update(benefited: Benefited, uid: string) {
    const documentPath = uid
    if (!documentPath) throw this.errorDocumentPath();

    await firebaseConfig.updateData(
      this.DEFAULT_REF,
      documentPath,
      benefited
    );
  }

  public async delete(uid: string) {
    const documentPath = uid
    if (!documentPath) throw this.errorDocumentPath();

    await firebaseConfig.delete(
      this.DEFAULT_REF,
      documentPath
    );
  }

  private errorDocumentPath() {
    return new Error('Document Path is null');
  }
}

export default new BenefitedService();
