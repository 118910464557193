import React from 'react';
import { Grid, TextField, withStyles } from '@material-ui/core';

type Props = { label: string, name: string, type?: string, placeholder?: string, disabled?: boolean, value: any, onChange: (event: any) => void };

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#707070',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#0000003b',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#0000003b',
      },
      '&:hover fieldset': {
        borderColor: '#0000003b',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#0000003b',
      },
    },
    '& .MuiInputBase-input': {
      '&::placeholder': {
        fontSize: 14,
      }
    }
  },
})(TextField);

function TextFieldSimpleRegister(props: Props) {
  const { label, name, type, disabled, placeholder, value, onChange } = props;

  return (
    <Grid item xs={12}>
      <CssTextField
        fullWidth
        variant="outlined"
        name={name}
        type={type}
        disabled={disabled}
        label={label}
        placeholder={placeholder}
        value={value}
        onChange={(event: any) => onChange(event.target.value)}
        InputLabelProps={{
          style: { color: '#707070', fontSize: '14px' },
        }}
      />
    </Grid>
  )
}

export default TextFieldSimpleRegister;