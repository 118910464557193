const styles: any = {
  label: {
    color: '#707070',
    fontWeight: 'bold',
  },
  checkboxDiv: {
    color: '#707070',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor: '#E6E6E6',
    border: '1px solid #0000003b',
    // borderLeft: 0,
    borderRadius: '0 4px 4px 0',
    '& .MuiTypography-body1': {
      fontSize: 14
    }
  }
};

export default styles;