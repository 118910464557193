import React, { useState } from 'react';
import Toast from '../Toast';

const withSnackbar = (WrappedComponent: any) => (props: any) => {

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [type, setType] = useState('');

  const openSnackbar = (type: any, message: any) => {
    setMessage(message);
    setType(type);
    setOpen(true);
  };

  const close = () => {
    setOpen(false);
  };

  return (
    <>
      <WrappedComponent openSnackbar={openSnackbar} {...props} />
      <Toast
        open={open}
        close={close}
        message={message}
        type={type}
      />
    </>
  );

};

export default withSnackbar;
