const styles: any = {
  mainGrid: {
    display: 'flex',
    height: '100vh',
    overflow: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F9F9F9',
    backgroundImage: 'url(/assets/bg_home.png)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    padding: '30px 0'
  },
  headerLogo: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-start',
    padding: '0 30px',
  },
  divMain: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  divCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  divButton: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 15,
    marginBottom: 10,
  },
  cardForm: {
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 0px 20px #00000033',
    borderRadius: 20,
    padding: 20,
    width: '100%',
  },
  mainLogo: {
    maxWidth: '180px',
    width: '100%',
    marginBottom: 15,
  },
  title22: {
    margin: '10px 0 50px',
    fontWeight: '600',
    fontSize: 22,
    color: '#707070',
    textAlign: 'center',
  },
  title18: {
    fontWeight: '600',
    fontSize: 14,
    color: '#0A0A0A59',
    textTransform: 'uppercase',
  },
  title16: {
    fontWeight: '600',
    fontSize: 15,
    color: '#0A4F9D',
    margin: '5px 0 10px',
  },
  buttonCancel: {
    fontSize: 14,
    fontWeight: 'bold',
    borderColor: '#D4586D',
    color: '#3E3E3E',
    padding: '10px 25px',
    // textTransform: 'capitalize',
    flex: 1,
    "&:hover": {
      backgroundColor: '#D4586D',
      color: '#fff',
    },
  },
  buttonRegister: {
    fontSize: 14,
    fontWeight: 'bold',
    marginLeft: '15px',
    backgroundColor: '#FFFFFF',
    padding: '10px 25px',
    color: '#F79657',
    // textTransform: 'capitalize',
    flex: 1,
    "&:hover": {
      backgroundColor: '#408BCC'
    }
  },
  checkboxDiv: {
    color: '#707070',
    display: 'flex',
    padding: '5px 0',
    '& .MuiTypography-body1': {
      fontSize: 14
    }
  }
};

export default styles;