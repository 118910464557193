import React, { useEffect, useState } from 'react';
import { format, parse } from 'date-fns';
import firebase from 'firebase';
import * as Yup from 'yup';

import {
  Grid,
  withStyles,
  Button,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

import Benefited from '../../../model/entities/Benefited';
import BenefitedService from '../../../services/BenefitedService';

import races from '../../../constants/races';
import sexes from '../../../constants/sexes';
import streets from '../../../constants/streets';
import neighborhoods from '../../../constants/neighborhoods';
import { messages } from '../../../constants/messages';

import withSnackbar from '../../templates/WithSnackbar';
import SelectField from '../../templates/SelectField';
import TextFieldSimpleRegister from '../../templates/TextFieldSimpleRegister';
import TextFieldWithUnkonwOption from '../../templates/TextFieldWithUnkonwOption';
import TextFieldWithMask from '../../templates/TextFieldWithMask';
import TextFieldWithMaskAndOption from '../../templates/TextFieldWithMaskAndOption';

import styles from './styles';
import convertDocToData from '../../../utils/convertDocToArray';
import UserService from '../../../services/UserService';

type Props = { classes: any, openSnackbar: any, history: any };

function FormPage(props: Props) {
  const { classes, history, openSnackbar } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [filterStreets, setFilterStreets] = useState<any[]>();
  const [street, setStreet] = useState<string>('');
  const [neighborhood, setNeighborhood] = useState<string>('');
  const [county, setCounty] = useState<string>('MADRE DE DEUS');
  const [number, setNumber] = useState<string>('');
  const [complement, setComplement] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [birthdate, setBirthdate] = useState<string>('');
  const [sex, setSex] = useState<string>('');
  const [race, setRace] = useState<string>('');
  const [motherName, setMotherName] = useState<string>('');
  const [motherNameUnkown, setMotherNameUnkown] = useState<boolean>(false);
  const [fatherName, setFatherName] = useState<string>('');
  const [fatherNameUnkown, setFatherNameUnkown] = useState<boolean>(false);
  const [phoneIsWhatsApp, setPhoneIsWhatsApp] = useState<boolean>(false);
  const [cpf, setCpf] = useState<string>('');
  const [nic, setNic] = useState<string>('');
  const [nis, setNis] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [hasHouseMoreThanFiveYears, setHasHouseMoreThanFiveYears] = useState<boolean>(false);
  const [manOrWomanOfTheHouse, setManOrWomanOfTheHouse] = useState<boolean>(false);
  const [noFormalJob, setNoFormalJob] = useState<boolean>(false);
  const [familiarIncomeIsLessThanOneSalary, setFamiliarIncomeIsLessThanOneSalary] = useState<boolean>(false);
  const [noOtherBenefits, setNoOtherBenefits] = useState<boolean>(false);
  const [trueInfo, setTrueInfo] = useState<boolean>(false);

  useEffect(() => {
    const user = history.location.state?.user;
    const cpf = history.location.state?.cpf;

    if (!user && !cpf) {
      history.push('/');
    }

    if (user) {
      initData(user);
    } else {
      setCpf(cpf);
    }
  }, [history])

  useEffect(() => {
    if (neighborhood) {
      const object = neighborhoods.find((n: any) => n.name === neighborhood);
      console.log(object);
      const filterStreets = streets.filter((st: any) => st.neighborhoodId === object?.id);
      setFilterStreets(filterStreets);
    } else {
      setFilterStreets(streets);
    }
  }, [neighborhood]);

  useEffect(() => {
    if (motherNameUnkown === true) setMotherName('');
  }, [motherNameUnkown]);

  useEffect(() => {
    if (fatherNameUnkown === true) setFatherName('');
  }, [fatherNameUnkown]);

  function initData(user: any) {
    setStreet(user.logradouro);
    setNeighborhood(user.bairro);
    setNumber(user.numero);
    setComplement(user.complemento);
    setName(user.nome);
    setBirthdate(user.data_nascimento);
    setSex(user.sexo);
    setMotherName(user.nome_mae);
    setMotherNameUnkown(user.nome_mae === 'NÃO DECLARADO' ? true : false);
    setFatherName(user.nome_pai);
    setFatherNameUnkown(user.nome_pai === 'NÃO DECLARADO' ? true : false);
    setPhoneIsWhatsApp(user.whatsapp === 'SIM' ? true : false);
    setCpf(user.cpf);
    setNic(user.nic);
    setPhone(user.telefone);
  }

  function handleError(e: any) {
    openSnackbar('warning', e);
  }

  function onCancel() {
    history.goBack();
  }

  const isUserAlreadyRegistered = async () => {
    try {
      const responseByCpf = await BenefitedService.getAllWithFilter([{ fieldPath: 'cpf', opStr: '==', value: cpf }]);
      const benefitedByCpf = convertDocToData(responseByCpf?.docs)[0] as Benefited | undefined;
  
      if (benefitedByCpf) {
        return benefitedByCpf;
      }

      const responseByNic = await BenefitedService.getAllWithFilter([{ fieldPath: 'nic', opStr: '==', value: nic }]);
      const benefitedByNic = convertDocToData(responseByNic?.docs)[0] as Benefited | undefined;
      return benefitedByNic;
    } catch (error) {
      throw error;
    }
  }

  function needUpdateUser(data: any) {
    const user = history.location.state?.user;
    if (data.name !== user.nome || data.cpf !== user.cpf || data.nic !== user.nic
      || data.motherName !== user.nome_mae || data.fatherName !== user.nome_pai) {
      return true;
    }
    return false;
  }

  function needUpdateAddress(data: any) {
    const user = history.location.state?.user;
    if (data.number !== user.numero || data.complement !== user.complemento
      || data.neighborhood !== user.bairro || data.street !== user.logradouro) {
        return true;
    }
    return false;
  }

  async function onSubmit() {
    try {
      setLoading(true);

      const schema = Yup.object().shape({
        street: Yup.string().required(messages.emptyFields),
        neighborhood: Yup.string().required(messages.emptyFields),
        county: Yup.string().required(messages.emptyFields),
        number: Yup.string().required(messages.emptyFields),
        name: Yup.string().required(messages.emptyFields),
        birthdate: Yup.string().required(messages.emptyFields),
        sex: Yup.string().required(messages.emptyFields),
        race: Yup.string().required(messages.emptyFields),
        cpf: Yup.string().required(messages.emptyFields),
        nis: Yup.string().required(messages.emptyFields),
        phone: Yup.string().required(messages.emptyFields),
        hasHouseMoreThanFiveYears: Yup.bool().oneOf([true], messages.checkFields),
        manOrWomanOfTheHouse: Yup.bool().oneOf([true], messages.checkFields),
        noFormalJob: Yup.bool().oneOf([true], messages.checkFields),
        familiarIncomeIsLessThanOneSalary: Yup.bool().oneOf([true], messages.checkFields),
        noOtherBenefits: Yup.bool().oneOf([true], messages.checkFields),
        trueInfo: Yup.bool().oneOf([true], messages.checkFields),
      });

      await schema.validate({
        street,
        neighborhood,
        county,
        number,
        name,
        birthdate,
        sex,
        race,
        cpf,
        nis,
        phone,
        hasHouseMoreThanFiveYears,
        manOrWomanOfTheHouse,
        noFormalJob,
        familiarIncomeIsLessThanOneSalary,
        noOtherBenefits,
        trueInfo,
      });

      const date = parse(birthdate, 'dd/MM/yyyy', new Date());
      if (date.toString() === "Invalid Date") {
        handleError(messages.invalidDate);
        setLoading(false);
        return;
      }

      if (motherNameUnkown === false && !motherName?.length) {
        handleError('Preencha o nome da mãe');
        setLoading(false);
        return;
      }

      if (fatherNameUnkown === false && !fatherName?.length) {
        handleError('Preencha o nome do pai');
        setLoading(false);
        return;
      }

      const userVerified = await isUserAlreadyRegistered();
      if (userVerified) {
        throw new Error('Esse CPF ou NIC já está cadastrado.');
      }

      const user = history.location.state?.user;
      const dateFormat = parse(birthdate, 'dd/MM/yyyy', new Date());
      let finalBenefited: Benefited = {
        street,
        neighborhood,
        county,
        number,
        complement: complement.toUpperCase(),
        name: name.toUpperCase(),
        birthdate: firebase.firestore.Timestamp.fromDate(dateFormat),
        sex,
        race,
        motherName: motherName.toUpperCase(),
        motherNameUnkown,
        fatherName: fatherName.toUpperCase(),
        fatherNameUnkown,
        phoneIsWhatsApp,
        cpf,
        nic,
        nis,
        phone,
        hasHouseMoreThanFiveYears,
        manOrWomanOfTheHouse,
        noFormalJob,
        familiarIncomeIsLessThanOneSalary,
        noOtherBenefits,
        trueInfo,
        isNewUser: !user,
        createdAt: firebase.firestore.Timestamp.now(),
        updatedAt: firebase.firestore.Timestamp.now(),
      };

      await BenefitedService.create(finalBenefited);

      if (user) {

        const benefited: any = {
          name,
          motherName: motherNameUnkown ? 'NÃO DECLARADO' : motherName,
          fatherName: fatherNameUnkown ? 'NÃO DECLARADO' : fatherName,
          cpf,
          nic,
        }

        await UserService.updateUser(benefited, user.id);

        const object = streets.find((n: any) => n.name === street);
        if (object) {
          const address: any = {
            number: number,
            complement: complement,
            neighborhoodId: object.neighborhoodId,
            streetId: object.id,
          }

          const verifyNeedUpdateAddress = needUpdateAddress(finalBenefited);
          if (verifyNeedUpdateAddress) {
            console.log('verifyNeedUpdateAddress');
            await UserService.updateAddress(address, user.id);
          }
        }
      }

      setLoading(false);
      openSnackbar('success', 'Cadastrado com Sucesso');
      history.push('/confirmacao', { benefited: finalBenefited });
    } catch (error) {
      setLoading(false);
      handleError(error.message);
    }
  }

  return (
    <Grid container className={classes.mainGrid}>
      <Grid item xs={12} className={classes.divMain}>
        <Grid item xs={12} className={classes.headerLogo}>
          <img alt="Logo SFC" src="/assets/logo_travessia.png" className={classes.mainLogo} />
        </Grid>
        <span className={classes.title22}>Cadastramento do Programa Travessia</span>
        <Grid item xs={11} className={classes.cardForm}>
          <span className={classes.title18}>Dados do Endereço</span>
          <Grid container item xs={12} spacing={2} style={{ margin: '10px 0 15px' }}>
            <Grid item xs={12} sm={6} md={5}>
              <SelectField
                label="Bairro *"
                value={neighborhood}
                onChange={setNeighborhood}
                name="neighborhood"
                array={neighborhoods}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <SelectField
                label="Logradouro *"
                value={street}
                onChange={setStreet}
                name="street"
                array={filterStreets}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <TextFieldSimpleRegister
                label="Número *"
                name="number"
                type="number"
                placeholder="N°"
                value={number}
                onChange={setNumber}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={7}>
              <TextFieldSimpleRegister
                name="complement"
                label="Complemento"
                value={complement}
                onChange={setComplement}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <TextFieldSimpleRegister
                disabled
                name="county"
                label="Município *"
                value={county}
                onChange={() => { }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={11} className={classes.cardForm} style={{ margin: '20px 0' }}>
          <span className={classes.title18}>Dados do Responsável Familiar</span>
          <Grid container item xs={12} spacing={2} style={{ margin: '10px 0 15px' }}>
            <Grid item xs={12} sm={6} md={4}>
              <TextFieldSimpleRegister
                name="name"
                label="Nome Completo *"
                placeholder="Nome Completo"
                value={name}
                onChange={setName}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2}>
              <TextFieldWithMask
                mask="99/99/9999"
                value={birthdate}
                onChange={setBirthdate}
                name="birthdate"
                placeholder="DD/MM/YYYY"
                label="Data de Nascimento *"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <SelectField
                label="Sexo *"
                name="sex"
                value={sex}
                onChange={setSex}
                array={sexes}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <SelectField
                label="Raça/Cor *"
                name="race"
                value={race}
                onChange={setRace}
                array={races}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldWithUnkonwOption
                disabled={motherNameUnkown}
                onUnkownChecked={setMotherNameUnkown}
                name="motherName"
                label="Nome da Mãe"
                value={motherName}
                onChange={setMotherName}
                checked={motherNameUnkown}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldWithUnkonwOption
                disabled={fatherNameUnkown}
                onUnkownChecked={setFatherNameUnkown}
                name="fatherName"
                label="Nome do Pai"
                value={fatherName}
                onChange={setFatherName}
                checked={fatherNameUnkown}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextFieldWithMask
                mask="999.999.999-99"
                type="cpf"
                value={cpf}
                onChange={setCpf}
                name="cpf"
                label="CPF *"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextFieldWithMask
                mask="9999999"
                name="nic"
                label="NIC"
                value={nic}
                onChange={setNic}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <TextFieldSimpleRegister
                type="number"
                name="nis"
                label="NIS (atualizado) *"
                value={nis}
                onChange={setNis}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldWithMaskAndOption
                mask="(99) 99999-9999"
                label="Telefone Celular *"
                checkLabel="É WhatsApp?"
                value={phone}
                onChange={setPhone}
                name="phone"
                checked={phoneIsWhatsApp}
                onUnkownChecked={setPhoneIsWhatsApp}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={11} className={classes.cardForm}>
          <span className={classes.title18}>Termos de Aceite</span>
          <Grid item xs={12} style={{ margin: '10px 10px 15px' }}>
            <Grid item xs={12} className={classes.checkboxDiv}>
              <FormControlLabel
                style={{ marginRight: 0 }}
                control={
                  <Checkbox
                    checked={hasHouseMoreThanFiveYears}
                    onChange={(e: any) => setHasHouseMoreThanFiveYears(e.target.checked)}
                    name="unknown"
                    color="primary"
                  />
                }
                label="Atesto que possuo residência e domicílio no Município há mais de (05) anos."
              />
            </Grid>
            <Grid item xs={12} className={classes.checkboxDiv}>
              <FormControlLabel
                style={{ marginRight: 0 }}
                control={
                  <Checkbox
                    checked={manOrWomanOfTheHouse}
                    onChange={(e: any) => setManOrWomanOfTheHouse(e.target.checked)}
                    name="unknown"
                    color="primary"
                  />
                }
                label="Atesto que sou homem maior de 18 anos ou mulher mãe e chefe de família."
              />
            </Grid>
            <Grid item xs={12} className={classes.checkboxDiv}>
              <FormControlLabel
                style={{ marginRight: 0 }}
                control={
                  <Checkbox
                    checked={noFormalJob}
                    onChange={(e: any) => setNoFormalJob(e.target.checked)}
                    name="unknown"
                    color="primary"
                  />
                }
                label="Atesto que não possuo emprego formal ativo."
              />
            </Grid>
            <Grid item xs={12} className={classes.checkboxDiv}>
              <FormControlLabel
                style={{ marginRight: 0 }}
                control={
                  <Checkbox
                    checked={familiarIncomeIsLessThanOneSalary}
                    onChange={(e: any) => setFamiliarIncomeIsLessThanOneSalary(e.target.checked)}
                    name="unknown"
                    color="primary"
                  />
                }
                label="Atesto que não possuo renda familiar que ultrapasse um salário mínimo."
              />
            </Grid>
            <Grid item xs={12} className={classes.checkboxDiv}>
              <FormControlLabel
                style={{ marginRight: 0 }}
                control={
                  <Checkbox
                    checked={noOtherBenefits}
                    onChange={(e: any) => setNoOtherBenefits(e.target.checked)}
                    name="unknown"
                    color="primary"
                  />
                }
                label="Atesto que não recebo nenhum outro benefício previdenciário, assistencial, seguro desemprego ou programa de tranferência de renda federal (exceto Programa Bolsa Família), estadual ou municipal (Madre Social)."
              />
            </Grid>
            <Grid item xs={12} className={classes.checkboxDiv}>
              <FormControlLabel
                style={{ marginRight: 0 }}
                control={
                  <Checkbox
                    checked={trueInfo}
                    onChange={(e: any) => setTrueInfo(e.target.checked)}
                    name="unknown"
                    color="primary"
                  />
                }
                label="Atesto para os devidos fins que as informações fornecidas acima são verdadeiras e tenho ciência das punições perante a lei caso tais informações não sejam verídicas."
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} className={classes.divCenter} style={{ margin: '20px 0', alignSelf: 'flex-end', padding: '0 50px' }}>
          <Button disabled={loading} variant="contained" className={classes.buttonCancel} onClick={onCancel}>
            Cancelar
          </Button>
          <Button disabled={loading} variant="contained" className={classes.buttonRegister} onClick={onSubmit}>
            {loading ? <CircularProgress size={25} /> : "Salvar"}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(withSnackbar(FormPage));