type Race = { name: string }

const races: Array<Race> = [
  { name: "Branca" },
  { name: "Preta" },
  { name: "Parda" },
  { name: "Amarela" },
  { name: "Indígena" },
  { name: "Sem informação" },
];

export default races;