// eslint-disable-next-line import/prefer-default-export
export const messages = {
    // GENERAL
    serverFail: 'Ops, nosso servidor não pode processar sua requisição, tente novamente mais tarde.',
    invalidPassword: 'Senha inválida.',
    userNotAlllowed: 'Você não tem permissão para acessar esta funcionalidade.',
    incorrectPassword: 'Senha incorreta',
    userNotFound: 'Este usuário não foi encontrado.',
    emptyFields: 'Preencha os campos obrigatórios',
    updatedSuccessfully: 'Atualizado com sucesso',
    createdSuccessfully: 'Cadastrado com sucesso',
    checkFields: 'Os termos de aceite são obrigatórios',
  
    // LOGIN
    loginTryLater: 'Não foi possível acessar o sistema, tente novamente mais tarde.',
    loginEmptyFields: 'Preencha todos os campos para acessar o sistema.',
    authenticationFailure: 'Não foi possível realizar o login',
  
    // SESSÃO
    sessionExpired: 'Você foi desconectado por ter ficado muito tempo inativo, faça login novamente.',
    invalidSession:
      'Percebemos algo estranho no seu acesso, precisamos que você faça login novamente.',
    blacklistedUser: 'Você está impedido de acessar o sistema, contate seu gestor.',
  
    // PERFIL
    profileUpdate: 'Perfil atualizado!',
    passwordUpdate: 'Senha atualizada!',
    profileNameError: 'Não foi possível alterar seu nome, tente novamente.',
    profileEmailError: 'O e-mail digitado é inválido, tente novamente',
    profileInputError: 'Existem erros nos dados digitados. Verifique e tente novamente.',
  
    // CADASTRO DE USUÁRIO
    userCreated: 'Usuário cadastrado com sucesso! Ele receberá um e-mail com a senha inicial.',
    userRegistrationInputError: 'Existem erros nos dados digitados. Corrija e tente novamente.',
    userRegistrationFailure: 'Não foi possível realizar o cadastro',
    emailError: 'Este email já está sendo utilizado',
    emailCredentialError: 'Não foi possível realizar o login, este email já está sendo utilizado',

    // CADASTRO DE CIDADÃO
    citizenCreated: 'Cidadão cadastrado com sucesso!',
    citizenRegistrationInputError: 'Existem erros nos dados digitados. Corrija e tente novamente.',
    citizenRegistrationFailure: 'Não foi possível realizar o cadastro',
    citizenAlreadyExists: 'Esse cidadão já está cadastrado no sistema',
    photoCannotBeEmptyForRsponsable: 'A foto é obrigatória para o responsável familiar',
    
     // CADASTRO DE IMÓVEL
    missingCoordinates: 'Clique em um local no mapa para indicar a localização do imóvel',
    missingStreet: 'Selecione um Logradouro.',
    
    // CADASTRO DE LOGRADOURO
    streetAlreadyExists: 'Já existe um logradouro com esse tipo, nome e bairro.',

    // CADASTRO DE BENEFÍCIO
    benefitCreated: 'Benefício cadastrado com sucesso!',
    benefitRegistrationInputError: 'Existem erros nos dados digitados. Corrija e tente novamente.',
    benefitRegistrationFailure: 'Não foi possível realizar o cadastro',

    // CONCEDER BENEFÍCIO
    benefitGranted: 'Benefício concedido cadastrado com sucesso!',
    grantBenefitFailure: 'Não foi possível conceder o benefício',
    citizenNotFound: 'Não foi possível encontrar o cidadão',
    citizenDoesntHaveNis: 'O cidadão precisa ter NIS para ter o benefício concedido',
    alreadyHaveBenefit: 'O cidadão já possui esse benefício',
    invalidValidationCode: 'Código de validação inválido',
    codeUpdated: 'Código atualizado com sucesso',
    updateCodeError: 'Não foi possível atualizar o código',

    // MUDANÇA DE STATUS DO BENEFÍCIO CONCEDIDO
    GrantedBenefitActivatedSuccess: 'Benefício ativado com sucesso',
    GrantedBenefitCanceledSuccess: 'Benefício cancelado com sucesso',
    GrantedBenefitSuspendedSuccess: 'Benefício suspenso com sucesso',
    changeGrantedBenefitStatusFailure: 'Não foi possível mudar o status do benefício',
  
    // CADASTRO DE PACIENTE
    invalidName: 'Preencha o nome completo',
    invalidCpf: 'CPF inválido',
    invalidEmail: 'Email inválido',
    invalidInternalCode: 'O número do prontuário do paciente já existe',
    invalidDate: 'Data inválida',
    invalidPrivacyPolitics: 'É necessário aceitar os termos de uso e política de privacidade',
    invalidCns: 'CNS inválido',
  
    // RECUPERAÇÃO DE SENHA
    recoverInvalidEmail: 'Não existe usuário vinculado a este email',
    recoverInvalidPassword: 'A senha informada é invalida.',
    recoverRequestStillOpen:
      'Você ainda possui uma solicitação em aberto, verifique sua caixa de entrada e Spam.',
    recoverInvalidToken:
      'O seu pedido de redefinição está corrompido, verifique o email que te mandamos.',
    recoverExpiredToken: 'O seu pedido de redefinição expirou.',
    recoverNotFound: 'O seu pedido de redefinição não é válido.',
    recoverChecked: 'Este pedido de redefinição já foi utilizado.',
  
    // MEDIÇÕES
    equalTime: 'Já existe outra medição com mesma data e horário',

    // PAS CADASTRO
    fullNameEmptyFields: 'O campo "Nome Completo" é obrigarório.',
    birthDateEmptyFields: 'O campo "Data de Nascimento" é obrigarório.',
    sexEmptyFields: 'O campo "Sexo" é obrigarório.',
    registerStatusEmptyFields: 'O campo "Status do Cadastro" é obrigarório.',
    nibEmptyFields: 'O campo "NIB" é obrigarório.',
    nisEmptyFields: 'O campo "NIS" é obrigarório.',
    cpfEmptyFields: 'O campo "CPF" é obrigarório.',
    rgEmptyFields: 'O campo "RG" é obrigarório.',
    pisEmptyFields: 'O campo "PIS" é obrigarório.',
    tituloEmptyFields: 'O campo "Título" é obrigarório.',
    zoneEmptyFields: 'O campo "Zona" é obrigarório.',
    sectionEmptyFields: 'O campo "Seção" é obrigarório.',
    schoolingEmptyFields: 'O campo "Escolaridade" é obrigarório.',
    occupationEmptyFields: 'O campo "Profissão" é obrigarório.',
    maritalStatusEmptyFields: 'O campo "Estado Civil" é obrigarório.',
    phoneEmptyFields: 'O campo "Telefone" é obrigarório.',
    numberEmptyFields: 'O campo "Número" é obrigarório.',
    neighborhoodEmptyFields: 'O campo "Bairro" é obrigarório.',
    streetEmptyFields: 'O campo "Logradouro" é obrigarório.',
  };
  