import React, { useState } from 'react';
import { Button, Grid, IconButton, Tooltip, withStyles } from '@material-ui/core';
import * as Yup from 'yup';

import Benefited from '../../../model/entities/Benefited';
import BenefitedService from '../../../services/BenefitedService';

import withSnackbar from '../../templates/WithSnackbar';
import ButtonMain from '../../templates/ButtonMain';
import TextFieldSimpleRegister from '../../templates/TextFieldSimpleRegister';

// import formatCpf from '../../../utils/formatCpf';
import convertDocToData from '../../../utils/convertDocToArray';
import { messages } from '../../../constants/messages';

import styles from './styles';
import UserService from '../../../services/UserService';
import { Lock } from '@material-ui/icons';

type Props = { classes: any, openSnackbar: any, history: any };

function ConsultPage(props: Props) {
  const { classes, history, openSnackbar } = props;
  const [cpfOrNic, setCpfOrNic] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  function handleError(e: any) {
    openSnackbar('warning', e);
  }

  const getUser = async (cpfOrNis: string) => {
    try {
      const users: any[] = await UserService.getAllByCpfOrNic(cpfOrNis);
      console.log(users);
      return users ? users[0] : users;
    } catch (error) {
      throw error;
    }
  }

  const isUserAlreadyRegistered = async (cpfOrNis: string) => {
    try {
      const responseByCpf = await BenefitedService.getAllWithFilter([{ fieldPath: 'cpf', opStr: '==', value: cpfOrNis }]);
      const benefitedByCpf = convertDocToData(responseByCpf?.docs)[0] as Benefited | undefined;
  
      if (benefitedByCpf) {
        return benefitedByCpf;
      }

      const responseByNic = await BenefitedService.getAllWithFilter([{ fieldPath: 'nic', opStr: '==', value: cpfOrNis }]);
      const benefitedByNic = convertDocToData(responseByNic?.docs)[0] as Benefited | undefined;
      return benefitedByNic;
    } catch (error) {
      throw error;
    }
  }

  async function onSubmit() {
    try {
      setLoading(true);

      const schema = Yup.object().shape({ cpfOrNic: Yup.string().required(messages.emptyFields) });
      await schema.validate({ cpfOrNic });
      
      const alreadyRegistered = await isUserAlreadyRegistered(cpfOrNic);
      if (alreadyRegistered) {
        throw new Error('Esse CPF ou NIC já está cadastrado.');
      };
      
      const user = await getUser(cpfOrNic);
      const data = {
        user,
        cpf: cpfOrNic,
      }

      history.push('/cadastro', data);

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      handleError(err.message);
    }
  }

  const handleRestrictedAreaClick = () => {
    history.push('/login');
  };

  return (
    <Grid container className={classes.mainGrid}>
      <Grid item xs={12} className={classes.divMain}>
        <img alt="Logo Madre" src="/assets/logo_travessia.png" className={classes.mainLogo} />
        
        <Grid item xs={12} className={classes.divInfo}>
          <span className={classes.title22}>Cadastramento do Programa Travessia</span>
          
          <Grid item md={5} sm={6} xs={11} className={classes.cardLogin}>
            <Grid container spacing={2}>
              <Grid item xs={12} className={classes.titleContainer}>
                <span className={classes.title18}>Bem Vindo!</span>
              </Grid>

              <Grid item xs={12} className={classes.titleContainer}>
                <span className={classes.title16}>Identificação através do CPF ou NIC</span>
              </Grid>

              <Grid item xs={12}>
                <TextFieldSimpleRegister
                  name="cpfOrNic"
                  type="number"
                  label="CPF ou NIC"
                  value={cpfOrNic}
                  onChange={setCpfOrNic}
                />
              </Grid>

              <Grid item xs={12} className={classes.divCenter}>
                <Grid item sm={6} xs={11} className={classes.divButton}>
                  <ButtonMain loading={loading} title="Consultar" onClick={onSubmit} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </Grid>

        <Grid item xs={12} className={classes.divInfo} style={{ flexDirection: 'column', alignItems: 'center' }}>
          <img alt="Logo Madre" src="/assets/logo_madre.png" className={classes.madreLogo} />
          <span className={classes.spanBottom}>Por <b>Horizon - Inovação e Tecnologia</b></span>
          <Tooltip title="Acessar área restrita" placement="left">
            <IconButton size="small" className={classes.restrictedAreaButton} onClick={handleRestrictedAreaClick}>
              <Lock />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default withStyles(styles)(withSnackbar(ConsultPage));