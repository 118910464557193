import React, { useContext, useState } from 'react';
import { Button, CircularProgress, Grid, withStyles } from '@material-ui/core';
import withSnackbar from '../../templates/WithSnackbar';
import styles from './styles';
import TextFieldSimpleRegister from '../../templates/TextFieldSimpleRegister';
import ButtonMain from '../../templates/ButtonMain';
import { AuthContext } from '../../../providers/Auth';

type Props = { classes: any, login: Function, openSnackbar: any, auth: any, history: any };

function LoginPage(props: Props) {
  const { loginWithEmail, setUserFirebase } = useContext(AuthContext);
  const { classes, history, openSnackbar } = props;
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  async function onLogin(event:any){
    try {
        event.preventDefault();
        setLoading(true);
        const userCredential = await loginWithEmail(email, password);
        setUserFirebase(userCredential.user);
        console.log(userCredential.user);
        history.push('/view');
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
        handleError(err.message);
      }
    }

  function handleError(e: any) {
    openSnackbar('warning', e);
  }

  return (
    <Grid container className={classes.mainGrid}>
      <Grid item xs={12} className={classes.divLogo}>
        <img alt="Logo Madre" src="/assets/logo_travessia.png" className={classes.mainLogo} />
      </Grid>
        <Grid container spacing={2} md={5} sm={6} xs={11} className={classes.cardLogin}>
          <Grid item xs={12} className={classes.titleItem}>
              <span className={classes.title18}>Fazer Login</span>
          </Grid>
          <Grid item xs={12} className={classes.titleItem}>
            <span className={classes.title16}>Entre com seu e-mail e senha</span>
          </Grid>
          <form onSubmit={onLogin}>
            <Grid container spacing={2} className={classes.divForm}>
                <Grid item xs={12}>
                    <TextFieldSimpleRegister
                    name="email"
                    type="string"
                    label="Endereço de e-mail"
                    value={email}
                    onChange={setEmail}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextFieldSimpleRegister
                    name="password"
                    type="password"
                    label="Senha"
                    value={password}
                    onChange={setPassword}
                    />
                </Grid>
                <Grid item sm={6} xs={11}>
                    <Button variant="contained" color="primary" className={classes.loginButton} title="Login" type="submit">
                      { loading ? <CircularProgress color="secondary" size={25} /> : "Login"}
                    </Button>
                </Grid>
            </Grid>
          </form>
        </Grid>
      <Grid item xs={12} className={classes.divBottom}>
        <img alt="Logo Madre" src="/assets/logo_madre.png" className={classes.madreLogo} />
        <span className={classes.spanBottom}>Por <b>Horizon - Inovação e Tecnologia</b></span>
      </Grid>
    </Grid>
  )
}
export default withStyles(styles)(withSnackbar(LoginPage));