import React, { createContext, useEffect, useState } from 'react';
import firebaseConfig from '../../config/firebase';

export const AuthContext = createContext({
  userFirebase: null as any,
  setUserFirebase: null as any,
  firebaseAuth: null as any,
  loginWithEmail: null as any,
  logout: null as any,
});

const AuthProvider = ({ children }: React.PropsWithChildren<React.ReactNode>): React.ReactElement => {
  const firebaseAuth = firebaseConfig.getFirebaseApp().auth();

  const [userFirebase, setUserFirebase] = useState<any>(null);

  const getUserFirebase = async () => {
    const currentUser = await firebaseConfig.getCurrentUser();
    setUserFirebase(currentUser);
  };

  useEffect(() => {
    getUserFirebase();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        userFirebase,
        setUserFirebase,
        firebaseAuth,
        loginWithEmail: async (email: string, password: string) => {
          return firebaseAuth.signInWithEmailAndPassword(email, password);
        },
        logout: async () => {
            await firebaseAuth.signOut();
          },
      }}
    >
        {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
