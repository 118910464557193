import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/performance";
import { Filter } from "../../model/entities/Filter";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

class Firebase {
  private firebaseApp: firebase.default.app.App;

  constructor() {
    if (!firebase.default.apps.length) {
      this.firebaseApp = firebase.default.initializeApp(firebaseConfig);
    } else {
      this.firebaseApp = firebase.default.auth().app;
    }
    firebase.default.performance(this.firebaseApp);
  }

  public async getAllDataWithFilter(
    collection: string,
    filters: Filter[],
    options?: firebase.default.firestore.GetOptions
  ): Promise<firebase.default.firestore.QuerySnapshot<firebase.default.firestore.DocumentData>> {
    const queries: Array<any> = [
      firebase.default
        .firestore(this.firebaseApp as firebase.default.app.App)
        .collection(collection)
    ];

    filters.forEach((filter) => queries.push(
      queries[queries.length - 1].where(filter.fieldPath, filter.opStr, filter.value)
      ));

    return queries[queries.length - 1].get(options);
  }


  public async getAllData(
    collection: string,
    options?: firebase.default.firestore.GetOptions
  ): Promise<firebase.default.firestore.QuerySnapshot<any>> {
    return firebase.default.firestore(this.firebaseApp as firebase.default.app.App)
      .collection(collection)
      .get(options);
  }

  public async getData(
    collection: string,
    documentPath: string | undefined,
    options?: firebase.default.firestore.GetOptions
  ): Promise<firebase.default.firestore.DocumentSnapshot<any>> {
    return firebase.default
      .firestore(this.firebaseApp as firebase.default.app.App)
      .collection(collection)
      .doc(documentPath)
      .get(options);
  }

  public async setData(collection: string, documentPath: string | undefined, data: any): Promise<void> {
    await firebase.default
      .firestore(this.firebaseApp as firebase.default.app.App)
      .collection(collection)
      .doc(documentPath)
      .set(data);
  }

  public async addData(collection: string, data: any): Promise<void> {
    await firebase.default
      .firestore(this.firebaseApp as firebase.default.app.App)
      .collection(collection)
      .add(data);
  }

  public async updateData(collection: string, documentPath: string | undefined, data: any): Promise<void> {
    await firebase.default
      .firestore(this.firebaseApp as firebase.default.app.App)
      .collection(collection)
      .doc(documentPath)
      .update(data);
  }

  public async delete(collection: string, documentPath: string): Promise<void> {
    await firebase.default
      .firestore(this.firebaseApp as firebase.default.app.App)
      .collection(collection)
      .doc(documentPath)
      .delete();
  }

  public async getAllDataWithLimit(
    collection: string,
    limit: number,
    orderBy?: Array<string>,
    filters?: Filter[],
    options?: any
  ): Promise<any> {
    const queries: Array<any> = [firebase.default.firestore(this.firebaseApp as firebase.default.app.App).collection(collection)];
    if (orderBy && orderBy?.length > 0) orderBy.forEach(orderBy => queries.push(queries[queries.length - 1].orderBy(orderBy)));
    if (filters && filters?.length > 0) filters?.forEach(filter => queries.push(queries[queries.length - 1].where(filter.fieldPath, filter.opStr, filter.value)));
    return queries[queries.length - 1].limit(limit).get(options);
  }

  public async getAllDataWithStartAfter(
    collection: string,
    limit: number,
    startAfter: any,
    orderBy?: Array<string>,
    filters?: Filter[],
    options?: any
  ): Promise<any> {
    const queries: Array<any> = [firebase.default.firestore(this.firebaseApp as firebase.default.app.App).collection(collection)];
    if (orderBy && orderBy?.length > 0) orderBy.forEach(orderBy => queries.push(queries[queries.length - 1].orderBy(orderBy)));
    if (filters && filters?.length > 0) filters?.forEach(filter => queries.push(queries[queries.length - 1].where(filter.fieldPath, filter.opStr, filter.value)));
    return queries[queries.length - 1].startAfter(startAfter).limit(limit).get(options);
  }

  public getNextDoc(
    collection: string,
  ): firebase.default.firestore.DocumentReference<firebase.default.firestore.DocumentData> {
    return firebase.default
      .firestore(this.firebaseApp as firebase.default.app.App)
      .collection(collection)
      .doc();
  }

  public getFirebaseApp(): firebase.default.app.App {
    return this.firebaseApp;
  }

  public async getCurrentUser(): Promise<firebase.default.User | null> {
    const user = await Firebase.getUserByAuth(this.firebaseApp.auth());
    return user;
  }

  public static async getUserByAuth(auth: firebase.default.auth.Auth): Promise<firebase.default.User> {
    return new Promise((resolve, reject) => {
      const unsubscribe = auth.onAuthStateChanged((user: any) => {
        unsubscribe();
        resolve(user);
      }, reject);
    });
  }
}

export default new Firebase();
