const styles: any = {
  mainGrid: {
    display: 'flex',
    height: '100vh',
    overflow: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F9F9F9',
    backgroundImage: 'url(/assets/bg_confirm.png)',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  spanBottom: {
    color: '#FFFFFF',
    fontSize: 14
  },
  infoContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    // justifyContent: 'center',
    // margin: '25px 0'
  },
  divMain: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // padding: '40px',
  },
  divMainColumn: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    // padding: '0 40px',
    // backgroundColor: '#F9F9F9',
  },
  divLoading: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 40px',
    backgroundColor: '#F9F9F9',
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
  },
  divContent: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  divCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  divButton: {
    marginLeft: 20,
  },
  cardLogin: {
    display: 'flex',
    backgroundColor: '#FFFFFF',
    borderRadius: 10,
    // border: '1px solid #707070',
    padding: 30,
    justifyContent: 'space-around',
    alignItems: 'center',
    boxShadow: '0px 0px 20px #00000026',
    marginTop: 10,
    flexWrap: 'wrap',
  },
  logoSfc: {
    maxWidth: '160px',
    width: '100%',
    marginBottom: 15,
  },
  imgPhone: {
    maxWidth: '90px',
    width: '100%',
    marginBottom: 15,
    display: 'none',
  },
  imgEnd: {
    // maxWidth: '160px',
    width: '100%',
    // marginBottom: 15,
  },
  title22: {
    margin: '0 0 30px',
    fontWeight: '700',
    fontSize: 53,
    color: '#EB7C31',
    width: '100%',
  },
  title22Mobile: {
    display: 'none',
    textAlign: 'center',
    margin: '0 0 20px',
    fontWeight: '700',
    fontSize: 18,
    color: '#EB7C31',
    width: '100%',
  },
  title18: {
    fontWeight: '700',
    fontSize: 18,
    color: '#0A4F9D',
    textAlign: 'center'
  },
  title16: {
    fontWeight: '600',
    fontSize: 15,
    color: '#0A4F9D',
    margin: '5px 0 10px',
    textAlign: 'center'
  },
  title16Black: {
    fontSize: 20,
    margin: '5px 0 10px',
    textAlign: 'center'
  },
  title16BlackLeft: {
    fontSize: 18,
    margin: '5px 0 10px',
    width: '100%',
  },
  '@media (max-width: 900px)': {
    mainGrid: {
      backgroundPosition: 'inherit',
    },
    imgEnd: {
      width: '130px',
    },
    infoContainer: {
      flexDirection: 'column-reverse',
      margin: 0,
      padding: 0,
    },
    buttonRegister: {
      backgroundColor: '#177AFC',
    },
    title22: {
      textAlign: 'center',
      fontSize: 40,
    },
    title16Black: {
      fontSize: 17,
      margin: '0px 0 30px',
      textAlign: 'left'
    },
    downloadSubtitleMobile: {
      display: 'flex',
    },
    downloadSubtitleDesktop: {
      display: 'none',
    },
    divMain: {
      padding: '10px 0px 10px 0px'
    },
    divButton: {
      margin: 0,
    },
  },
  '@media (max-width: 600px)': {
    logoSfc: {
      marginTop: 10,
    },
    title22: {
      display: 'none',
    },
    title22Mobile: {
      width: '100%',
      textAlign: 'center',
      display: 'unset',
    },
    title16BlackLeft: {
      fontSize: 15,
      textAlign: 'center',
    },
    imgPhone: {
      display: 'unset',
    },
  },
};

export default styles;