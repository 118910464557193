import React from 'react';
import ReactInputMask from 'react-input-mask';
import { TextField, withStyles } from '@material-ui/core';

type Props = { label: string, name: string, mask: string, type?: string, placeholder?: string, disabled?: boolean, value: any, onChange: (event: any) => void };

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: '#707070',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#0000003b',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '4px 0 0 4px',
      '& fieldset': {
        borderColor: '#0000003b',
      },
      '&:hover fieldset': {
        borderColor: '#0000003b',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#0000003b',
      },
    },
    '& .MuiInputBase-input': {
      '&::placeholder': {
        fontSize: 14,
      }
    }
  },
})(TextField);

function TextFieldWithMask(props: Props) {
  const { label, name, type, mask, disabled, placeholder, value, onChange } = props;

  return (
    <ReactInputMask
      mask={mask}
      type={type}
      value={value}
      onChange={(event: any) => onChange(event.target.value)}
      name={name}
    >
      {() => (
        <CssTextField
          fullWidth
          variant="outlined"
          label={label}
          disabled={disabled}
          placeholder={placeholder}
          InputLabelProps={{
            style: { color: '#707070', fontSize: '14px' },
          }}
        />
      )}
    </ReactInputMask>
  )
}

export default TextFieldWithMask;