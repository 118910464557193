const styles: any = {
    button: {
        backgroundColor: '#F79657',
        fontSize: 13,
        width: "100%",
        "&:hover": {
            backgroundColor: '#ef735a'
        }
    },
};

export default styles;