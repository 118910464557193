import React, { useState, useEffect, useContext } from 'react';
import { format, fromUnixTime } from 'date-fns'
import { AppBar, Button, Grid, IconButton, TableHead, Toolbar, Tooltip, withStyles, TextField, } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import ExitToApp from '@material-ui/icons/ExitToApp';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from '@material-ui/pickers';
import PtLocale from "date-fns/locale/pt-BR";
import withSnackbar from '../../templates/WithSnackbar';
import Benefited from '../../../model/entities/Benefited';
import { AuthContext } from '../../../providers/Auth';
import styles from './styles';
import firebase from '../../../config/firebase'
import convertDocToData from '../../../utils/convertDocToArray';


type Props = { classes: any, openSnackbar: any, auth: any, };

function ViewPage(props:Props) {
  const { classes } = props;
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [benefiteds, setBenefiteds] = useState<any>([]);
  const [benefitedRef, setBenefitedRef] = useState<Promise<any>>();
  const [name, setName] = useState<any>();
  const [cpf, setCpf] = useState<any>();
  const [nic, setNic] = useState<any>();
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date(),);
  const [loading, setLoading] = useState<boolean>(false);
  const { logout, setUserFirebase } = useContext(AuthContext);

  useEffect(() => {
        const data:any[] = [];
        if (benefitedRef){
            setLoading(true);
            benefitedRef.then((snapshot: any[]) => {
                snapshot.forEach(childSnapshot => {
                    data.push(childSnapshot);
                    setBenefiteds(convertDocToData(data).map(infos=>{
                        const dateFormat = format(fromUnixTime(infos.updatedAt.seconds),'dd/MM/yyyy')
                        return {
                            ...infos,
                            updatedAt: dateFormat,
                        }
                    }))
                });
                setLoading(false);
            });
        }
    },[benefitedRef]);

  const Logout = () => {
    logout();
    setUserFirebase();
    }

  async function onLogout(){
      Logout();
  }

  async function onFilter(event:any){
        event.preventDefault();
        let filtered = []
        if(name) {
            filtered.push({
                fieldPath: 'nome',
                opStr: '==',
                value: name
                });
            }
        if (cpf) {
            filtered.push({
                fieldPath: 'cpf',
                opStr: '==',
                value: cpf
                });
            }
        if (nic) {
            filtered.push({
                fieldPath: 'nic',
                opStr: '==',
                value: nic
                });
            }
        if(selectedDate){
                const initialDateTimestamp = selectedDate.setHours(0, 0, 0);
                const finalDateTimestamp = selectedDate.setHours(23, 59, 59);
                filtered.push({
                    fieldPath: 'updatedAt',
                    opStr: '>=',
                    value: new Date(initialDateTimestamp)
                });
                filtered.push({
                    fieldPath: 'updatedAt',
                    opStr: '<=',
                    value: new Date(finalDateTimestamp)
                });
            }
        setBenefitedRef(firebase.getAllDataWithFilter('benefited', filtered));
    }

    async function onExport(){}

  const emptyRows = rowsPerPage - Math.min(rowsPerPage,benefiteds.length - page * rowsPerPage);


  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
        <AppBar color="primary" className={classes.appBar}>
            <Toolbar className={classes.toolBar}>
                <img alt="Logo Madre" src="/assets/logo_travessia.png" className={classes.mainLogo} />
                <Tooltip title="Sair">
                    <IconButton edge="end" color="default" aria-label="logout" onClick={onLogout}>
                        <ExitToApp />
                    </IconButton>
                </Tooltip>
            </Toolbar>
        </AppBar>
        <Grid container className={classes.mainGrid} spacing={5}>
            <Grid item xs={12} className={classes.tableItem}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={PtLocale}>
                    <Grid container className={classes.filterItem} >
                        <form noValidate autoComplete="off" onSubmit={onFilter}>
                            <TextField className={classes.formItem} label="Nome" type="string" value={name} onChange={(event: any) => setName(event.target.value)}/>
                            <TextField className={classes.formItem}  label="CPF" type="string" value={cpf} onChange={(event: any) => setCpf(event.target.value)}/>
                            <TextField className={classes.formItem}  label="NIC" type="string" value={nic} onChange={(event: any) => setNic(event.target.value)}/>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="dd/MM/yyyy"
                                label="Data de cadastro"
                                value={selectedDate}
                                onChange={handleDateChange}
                                KeyboardButtonProps={{'aria-label': 'change date',}}
                            />
                            <Button variant="contained" color="primary" type="submit" className={classes.filterButton}>Filtrar</Button>
                        </form>
                        {/* <Button variant="text" color="primary" className={classes.exportButton} onClick={onExport}>Exportar para Excel</Button> */}
                    </Grid>
                </MuiPickersUtilsProvider>
                {loading ? 
                <div className={classes.loading}>
                    <CircularProgress color="secondary" />
                </div>
                :
                    <TableContainer component={Paper} className={classes.table} >
                        <Table aria-label="custom pagination table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tableTitle}>Nome</TableCell>
                                    <TableCell className={classes.tableTitle}>Logradouro</TableCell>
                                    <TableCell className={classes.tableTitle}>Número</TableCell>
                                    <TableCell className={classes.tableTitle}>Bairro</TableCell>
                                    <TableCell className={classes.tableTitle}>CPF</TableCell>
                                    <TableCell className={classes.tableTitle}>NIC</TableCell>
                                    <TableCell className={classes.tableTitle}>Telefone</TableCell>
                                    <TableCell className={classes.tableTitle}>Data de cadastro</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                (rowsPerPage > 0
                                    ?benefiteds.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    :benefiteds
                                    ).map((infos: Benefited) => (
                                    <TableRow>
                                        <TableCell component="th" scope="row">
                                            {infos.name}
                                        </TableCell>
                                        <TableCell component='th' align="left">
                                            {infos.street}
                                        </TableCell>
                                        <TableCell style={{ width: 60 }} align="center">
                                            {infos.number}
                                        </TableCell>
                                        <TableCell style={{ width: 60 }} align="center">
                                            {infos.neighborhood}
                                        </TableCell>
                                        <TableCell style={{ width: 60 }} align="center">
                                            {infos.cpf}
                                        </TableCell>
                                        <TableCell style={{ width: 60 }} align="center">
                                            {infos.nic}
                                        </TableCell>
                                        <TableCell style={{ width: 120 }} align="center">
                                            {infos.phone}
                                        </TableCell>
                                        <TableCell style={{ width: 120 }} align="center">
                                            {infos.updatedAt}
                                        </TableCell>
                                    </TableRow>
                                ))}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                            <TableFooter>
                                <p>{ benefiteds.length } registros encontrados</p>
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, { label: 'Tudo', value: -1 }]}
                                    colSpan={3}
                                    count={benefiteds.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    SelectProps={{
                                        inputProps: { 'aria-label': 'Linhas por pagina' },
                                        native: true,
                                    }}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                />
                            </TableFooter>
                        </Table>
                    </TableContainer>
                }     
            </Grid>
            <Grid item xs={12} className={classes.footerItem}>
                <img alt="Logo Madre" src="/assets/logo_madre.png" className={classes.madreLogo} />
                <span className={classes.spanBottom}>Por <b>Horizon - Inovação e Tecnologia</b></span>
            </Grid>
        </Grid>
    </>
  );
}

export default withStyles(styles)(withSnackbar(ViewPage));