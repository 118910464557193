import axios from "axios";

axios.defaults.headers.get['Content-Type'] ='application/json;charset=utf-8';
axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';

const api = axios.create({
  baseURL: `${process.env.REACT_APP_NODE_ENV_IP}/api/`,
});

export default api;
