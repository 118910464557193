type Neighborhood = { id: number, name: string }

const neighborhoods: Array<Neighborhood> = [
  { id: 1, name: "CENTRO" },
  { id: 2, name: "CAIS" },
  { id: 3, name: "MIRIM" },
  { id: 4, name: "SUAPE" },
  { id: 5, name: "CACAO" },
  { id: 6, name: "ALTOS" },
  { id: 7, name: "CURURUPEBA" },
  { id: 8, name: "QUITERIA VELHA" },
  { id: 10, name: "APICUM" },
  { id: 11, name: "CAMINHO DA LUZ" },
  { id: 12, name: "BARBEIRINHO" },
  { id: 13, name: "AREA DE LAZER" },
  { id: 14, name: "MARIA GUARDA" },
  { id: 15, name: "NOVA MADRE DE DEUS" }
];

export default neighborhoods;