const styles: any = {
    appBar: {
      backgroundColor: 'white',
    },
    toolBar: {
      justifyContent: 'space-between', 
    },
    mainGrid: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      justifyContent: 'flex-start',
      alignItems: 'stretch',
      padding: '150px 80px 50px 80px',
      backgroundColor: '#F9F9F9',
      backgroundImage: 'url(/assets/bg_home.png)',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    },
    logoutItem: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'start',
      borderRadius: '20px',
    },
    logoItem: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      alignItems: 'center',
    },
    filterItem: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
    formItem: {
      padding: '0 10px'
    },
    tableItem: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      alignItems: 'center',
      backgroundColor: '#FFFFFF',
      borderRadius: 20,
      padding: '80px',
      boxShadow: '0px 0 20px #00000033',
    },
    footerItem: {
        display: 'flex',
        flexDirection: 'column', 
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    tableTitle: {
        backgroundColor:'#F79657',
        fontSize:18,
        color: '#FFFFFF',
        fontWeight: 'bold'
    },
    table: {
        justifyContent: 'center',
        maxHeight:500,
    },
    filterButton: {
        alignSelf: 'flex-end',
        justifyContent: 'start',
        // padding:'10px',
        margin:'10px'
    },
    mainLogo: {
      maxWidth: '150px',
    },
    madreLogo: {
      maxWidth: '120px',
      width: '100%',
      padding: '0 10px',
      marginBottom: 15,
    },
    spanBottom: {
      color: '#FFFFFF',
      fontSize: 14
    },
    loading: {
      display: 'flex',
      size:250,
      justifyContent: 'center',
      alignItems: 'center',
      padding:15,
      },
  };
  
  export default styles;