import React from 'react';
import { Button, CircularProgress, withStyles } from '@material-ui/core';

import styles from './styles';

type Props = { classes: any, onClick: Function, loading: boolean, title: string, endIcon?: any, disabled?: boolean };

function ButtonMain(props: Props) {
    const { classes, loading, title, onClick, disabled } = props;
    return (
        <Button {...props} disabled={loading || disabled} className={classes.button} variant="contained" color="primary" onClick={() => onClick()}>
            { loading ? <CircularProgress size={25} /> : title}
        </Button>
    );
}

export default withStyles(styles)(ButtonMain);