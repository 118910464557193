import React, { useContext } from 'react';
import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';
import ConsultPage from '../components/pages/ConsultPage';
import ConfirmPage from '../components/pages/ConfirmPage';
import ViewPage from '../components/pages/ViewPage';
import FormPage from '../components/pages/FormPage';
import LoginPage from '../components/pages/LoginPage';
import { AuthContext } from '../providers/Auth';
import TerminatePage from '../components/pages/TerminatePage';

function PublicRoute(props: any) {
  const { component: Component, rest } = props;
  return (
    <Route {...rest} render={propsRender => <Component {...propsRender} />} />
  );
}

function PrivateRoute(props: any) {
  const { component: Component, auth, rest } = props;
  return (
      <Route
          {...rest}
          render={props2 => auth ? (
              <Component user={auth} {...props2} />
          ) : (
                  <Redirect to={'/login'} />
              )}
      />
  );
}

function Router(props: any) {
  const { userFirebase } = useContext(AuthContext);
  return (
    <BrowserRouter>
      <Switch>
        <PublicRoute component={ConsultPage} path="/consult" exact />
        <PublicRoute component={TerminatePage} path="/" exact />
        <PublicRoute component={FormPage} path="/cadastro" />
        <PublicRoute component={ConfirmPage} path="/confirmacao" />
        
        <PublicRoute component={LoginPage} path="/login"/>
        <PrivateRoute auth={userFirebase} component={ViewPage} path="/view" />
      </Switch>
    </BrowserRouter>
  )
}

export default Router;
